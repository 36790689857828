import React, { useEffect, useState } from "react";
import { getAllWithdrawals, transferbtmeta, fetchUserBalaance ,withdrawalApproved } from "../../common/Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from "sweetalert2";
import * as XLSX from "xlsx"; // For Excel export
import { saveAs } from "file-saver"; // For file saving


function Withdrawls() {
  const { user, token } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState(""); // New state for search input
  const [loading, setLoading] = useState(false); // New loading state
  const [selectedFund, setSelectedFund] = useState({});
  const handleClose = () => setShow(false);
  const [approvedloading, setApprovedLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState(""); // Status filter
  const [filterDate, setFilterDate] = useState(""); // Date filter

  //12-12-2024 withdrawalapprove
  const [withdrawalapprove, setWithdrawalapprove] = useState(false);
  const [userbalance, setUserBalance] = useState({});
  const [id, setId] = useState("")
  const [proceedamount, setProceedAmount] = useState("")
  const [proccedaddress, setProceedAddress] = useState("")
  const [proccedstatus, setProceedStatus] = useState(0)


  // Fetch withdrawal data
  const get_user_withdrawal = async () => {
    setLoading(true); // Set loading to true when data is being fetched
    try {
      const datas = await getAllWithdrawals(token);
      setData(datas);
      setFilteredData(datas); // Initialize filtered data with full data
    } catch (error) {
      toast.error("Failed to fetch data");
    } finally {
      setLoading(false); // Set loading to false after data has been fetched or error occurred
    }
  };

  // Filter data based on the search query
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter data based on the search query
    const filtered = data.filter((row) => {
      return (
        row.userId?.username?.toLowerCase().includes(query.toLowerCase()) ||
        row.userId?.email?.toLowerCase().includes(query.toLowerCase()) ||
        row.remarks?.toLowerCase().includes(query.toLowerCase()) ||
        row.amount.toString().includes(query)
      );
    });

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page on search
  };

  const showSingleModel = (row) => {
    setShow(true);
    setSelectedFund(row);
  };

  const handlePayBtMeta = async (row, matchId, idd, amount, address) => {
    setSelectedFund(row)
    setWithdrawalapprove(true)
    const status = document.querySelector("#status").value;
    setProceedStatus(status)
    const data = await fetchUserBalaance(matchId, token)
    console.log('userstats', data)
    setUserBalance(data.data)
    setId(idd)
    setProceedAmount(amount)
    setProceedAddress(address)
  }

  

  const finalApproved = async () => {
    setApprovedLoading(true)
    try {
      let hashs = '';
      if (proccedstatus == 1) {
        const data = await transferbtmeta(proceedamount, proccedaddress)
        console.log(data)
        hashs = data;
      }
      const submitdata = {
        status: proccedstatus,
        hash: hashs
      }
      const approved = await withdrawalApproved(id, submitdata, token)
      if (approved.status == true) {
        setWithdrawalapprove(false)
        get_user_withdrawal()
        Swal.fire({
          icon: "success",
          title: "",
          text: approved.message,
        });

      }

    } catch (error) {
      setWithdrawalapprove(false)
      console.log(error)
    }
  }

  // Columns for the DataTable
  const columns = [
    { name: '#', selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1, width: "50px" },
    { name: 'Name', selector: row => row.userId?.username || '-NA-' },
    { name: 'Email', selector: row => row.userId?.email },
    { name: 'Amount', selector: row => `${Math.abs(row.amount)} BTMETA` },
    {
      name: 'wallet', selector: row => <>
        {
          row.type == 0 ? <><small className="btn btn-success btn-xs">Staking Wallet</small></> : row.type == 1 ? <><small className="btn btn-danger btn-xs">Affiliate Wallet</small></> : row.type == 3 ?
            <><small className="btn btn-warning btn-xs">Royality Wallet</small></> : <><small className="btn btn-info btn-xs">Refferal Wallet</small></>
        }

      </>
    },
    { name: 'Credit Date', selector: row => new Date(row.creditDate).toLocaleString() },
    {
      name: 'Status', selector: row => <>
        {row.status === 1 ? <small className="btn btn-success btn-xs">Approved</small> : row.status === 2 ? <small className="btn btn-danger btn-xs">Reject</small> : <small className="btn btn-warning btn-xs">Pending</small>}
      </>
    },
    {
      name: 'Change Status', selector: row => <>

        {
          row.status != 1 && <div className="status-change d-flex">
            <select className="form-control me-2" id="status">
              <option value='1'>Approved</option>
              <option value='2'>Reject</option>
            </select>
            <button className="btn-xs" onClick={() => handlePayBtMeta(row, row.matchId, row._id, Math.abs(row.amount), row.toaddress)} disabled={approvedloading}> {approvedloading ? 'Process..' : 'Submit'} </button>
          </div>
        }


      </>
    },
    { name: 'Remarks', selector: row => row.remarks },
    {
      name: 'Action',
      cell: row => <i className='fas fa-eye' style={{ color: "green", cursor: "pointer" }} onClick={() => showSingleModel(row)}></i>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  
  // Filter Data
  const filterData = () => {
    let filtered = data;

    // Filter by search query
    if (searchQuery) {
      filtered = filtered.filter(
        (row) =>
          row.userId?.username
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          row.userId?.email
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          row.remarks?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          row.amount.toString().includes(searchQuery)
      );
    }

    // Filter by status
    if (filterStatus) {
      filtered = filtered.filter(
        (row) => row.status.toString() === filterStatus
      );
    }

    // Filter by date
    if (filterDate) {
      filtered = filtered.filter((row) => {
        const rowDate = new Date(row.creditDate).toISOString().split("T")[0];
        return rowDate === filterDate;
      });
    }

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page
  };

  // Export Data to Excel
  const handleExport = () => {
    const exportData = filteredData.map((row, index) => ({
      "#": index + 1,
      Name: row.userId?.username || "-NA-",
      Email: row.userId?.email,
      Amount: `${Math.abs(row.amount)} BTMETA`,
      "Credit Date": new Date(row.creditDate).toLocaleString(),
      Status:
        row.status === 1
          ? "Approved"
          : row.status === 2
          ? "Rejected"
          : "Pending",
      Remarks: row.remarks,
      "To Address": row.toaddress,
      Hash: row.hash,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Withdrawals");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "Withdrawals.xlsx");
  };

  useEffect(() => {
    get_user_withdrawal();
  }, []);

  useEffect(() => {
    filterData();
  }, [searchQuery, filterStatus, filterDate]);

  const handleWithdrawalCloseClose = () => {
    setWithdrawalapprove(false)
  }

  return (
    <>
      <div className="container-xxl flex-grow-1">
      <div className="mb-3 mt-3 d-flex justify-content-between">
          <div className="d-flex w-100 gap-2 flex-column flex-lg-row flex-md-row">
            {/* Search Input */}
            <input
              type="text"
              className="form-control my-0 my-lg-2 my-md-2"
              placeholder="Search by Name, Email, Amount, Remarks"
              value={searchQuery}
              onChange={handleSearch}
            />

            {/* Status Filter */}
            <select
              className="form-control my-2"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <option value="">All Status</option>
              <option value="1">Approved</option>
              <option value="2">Rejected</option>
              <option value="0">Pending</option>
            </select>

            {/* Date Filter */}
            <input
              type="date"
              className="form-control my-0 my-lg-2 my-md-2"
              value={filterDate}
              onChange={(e) => setFilterDate(e.target.value)}
            />

            {/* Export Button */}
            <Button
              className="submit-screen btn-xs fs-6 w-100 w-25 my-2"
              onClick={handleExport}
              style={{ borderRadius: "5px !important" }}
            >
              Export to Excel
            </Button>
          </div>
        </div>

        {/* Loader: Show when data is loading */}
        {loading ? (
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p>Loading data...</p>
          </div>
        ) : (
          // DataTable: Show when data is loaded
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
            responsive
            paginationDefaultPage={currentPage}
            onChangePage={(page) => setCurrentPage(page)}
            noDataComponent={<p style={{ margin: "10px", textAlign: "center" }}>There are no records to display</p>}
          />
        )}

        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title className='pb-0'><h4 className='mb-0'>Transactions</h4></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className='table-responsive'>
                  <table className='table w-100'>
                    <tbody className='text-nowrap'>
                      <tr><td><b>Name</b></td><td>{selectedFund?.userId?.username}</td></tr>
                      <tr><td><b>Email</b></td><td>{selectedFund?.userId?.email}</td></tr>
                      <tr><td><b>Amount</b></td><td>{selectedFund?.amount} metabt</td></tr>
                      <tr><td><b>Remarks</b></td><td>{selectedFund?.remarks}</td></tr>
                      <tr><td><b>From Address</b></td><td>{selectedFund?.fromaddress}</td></tr>
                      <tr><td><b>To Address</b></td><td>{selectedFund?.toaddress}</td></tr>
                      <tr><td><b>Hash</b></td><td>{selectedFund?.hash}</td></tr>
                      <tr><td><b>Credit Date</b></td><td>{new Date(selectedFund?.creditDate).toLocaleString()}</td></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>


        <Modal size="lg" show={withdrawalapprove} onHide={handleWithdrawalCloseClose}>
          <Modal.Header closeButton>
            <Modal.Title>User Balance Detail {selectedFund?.userId?.username}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><b>Email : </b>{selectedFund?.userId?.email}</p>
            <p><b>Withdrawal Address : </b>{selectedFund?.toaddress}</p>
            <p><b>Withdrawal from Wallet: </b>{selectedFund.type == 0 ? <><small className="btn btn-success btn-xs">Staking Wallet</small></> : selectedFund.type == 1 ? <><small className="btn btn-danger btn-xs">Affiliate Wallet</small></> : selectedFund.type == 3 ?
              <><small className="btn btn-warning btn-xs">Royality Wallet</small></> : <><small className="btn btn-info btn-xs">Refferal Wallet</small></>}</p>
            <p><b>Withdrawal Amount: </b> {Math.abs(selectedFund.amount)} BTMETA</p>
            <table className='table w-100'>
              <tbody className='text-nowrap'>
                <tr><td><b>Staking Wallet</b></td><td>{userbalance?.data?.staking} BTMETA</td></tr>
                <tr><td><b>Affiliate Wallet</b></td><td>{userbalance?.data?.affility} BTMETA</td></tr>
                <tr><td><b>Royality Wallet</b></td><td>{userbalance?.data?.royalty} BTMETA</td></tr>
                <tr><td><b>Refferal Wallet</b></td><td>{userbalance?.data?.referral} BTMETA</td></tr>

              </tbody>
            </table>
            <div className="text-center">
              <p>Are you sure to {proccedstatus == 1 ? 'approved' : 'rejected'} this withdrawal ? </p>
              {
                approvedloading == true ? <p style={{color:"green"}}>Loading....</p> : <button onClick={finalApproved}>Submit</button>
              }
              
            </div>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleWithdrawalCloseClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </>
  );
}

export default Withdrawls;
