import React, { useEffect, useState } from 'react'
import { get_reward_update } from '../../../common/Api'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function StakingHistoryComponent({ data, title }) {
    const [selectedFund, setSelectedFund] = useState({});
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
   console.log(data)
    const showSingleModel = (row) => {
        setShow(true)
        setSelectedFund(row)
    }
    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row?.userId?.username,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => <>
             {row?.userId?.email}
            </>,
            sortable: true,
        },
        {
            name: 'Plan',
            selector: row => row?.planId?.name,
            sortable: true,
        },
        {
            name: 'Credit Amount',
            selector: row => (
                <>
                    {row.amount}  BTMETA

                </>
            ),
            sortable: true,
        },
        {
            name: 'Credit Date',
            selector: row => (
                <>
                    {new Date(row.creditDate).toLocaleString()}
                </>
            ),
            sortable: true,
        },
        {
            name: 'Remraks',
            selector: row => (
                <>
                    {row.remarks}
                </>
            ),
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => (
                <>
                    <i className='fas fa-eye' style={{ color: "green", cursor: "pointer" }} onClick={() => showSingleModel(row)}></i>
                </>
            ),
            sortable: true,
        },



    ];
    return (
        <div className="card mb-3">
            <h5 className="card-header">{title && title}</h5>
            <div className="card-body">
                {/* <div className="mb-3">
        <input
            type="text"
            className="form-control"
            placeholder="Search by Email"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
        />
    </div> */}
                <DataTable
                    columns={columns}
                    data={data.data}
                    pagination
                    highlightOnHover
                    striped
                />
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><h6>Transactions</h6></Modal.Title>
                </Modal.Header>
                <Modal.Body className="table-responsive">
                    <table className='table w-100'>
                        <tbody className='text-nowrap'>
                            <tr>
                                <td><b>Name</b></td>
                                <td>
                                    {selectedFund?.userId?.username}
                                </td>
                            </tr>
                            <tr>
                                <td><b>Email</b></td>
                                <td>
                                    {selectedFund?.userId?.email}
                                </td>
                            </tr>
                            <tr>
                                <td><b>Package</b></td>
                                <td>
                                    {selectedFund?.planId?.name}
                                </td>
                            </tr>
                            <tr>
                                <td><b>Amount</b></td>
                                <td>
                                    {selectedFund?.amount} BTMETA
                                </td>
                            </tr>
                            <tr>
                                <td><b>Remarks</b></td>
                                <td>
                                    {selectedFund?.remarks}
                                </td>
                            </tr>
                            <tr>
                                <td><b>From Address</b></td>
                                <td>
                                    {selectedFund?.fromaddress}
                                </td>
                            </tr>
                            <tr>
                                <td><b>To Address</b></td>
                                <td>
                                    {selectedFund?.toaddress}
                                </td>
                            </tr>
                            <tr>
                                <td><b>Hash</b></td>
                                <td>
                                    {selectedFund?.hash}
                                </td>
                            </tr>
                            <tr>
                                <td><b>Credit Date</b></td>
                                <td>
                                    {new Date(selectedFund?.creditDate).toLocaleDateString()}
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} style={{background: '#e4141696'}}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};


