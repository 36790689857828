import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { add_fund_user, add_user_rank, addUserRankCondition, find_fund_user, gerUserRank, singleUserRank, singleUserRankCondition, updateUserRank, updateUserRankCondition } from '../../common/Api';
import { useParams } from 'react-router-dom';


const RankConditionForm = () => {

    const params = useParams()

    const { id } = params;
    const { token } = useSelector((state) => state.user);

    const [name, setName] = useState('')
    const [amount, setAmount] = useState(null)
    const [rank, setRank] = useState('')
    const [ranks, setRanks] = useState({})
    const [getrank, setGetRank] = useState([])
    const [selfstaking, setSelfStaking] = useState(0)
    const [firstpool, setFirstPool] = useState(0)
    const [secondpool, setSecondPool] = useState(0)
    const [thirdpool, setThirdPool] = useState(0)
    const [metafoundercount, setMetafoundercount] = useState(0)
    const [metastartcount, setMetastartcount] = useState(0)
    const [metaroyalcount, setMetaroyalcount] = useState(0)
    const [metaprimecount, setMetaprimecount] = useState(0)
    const [selectedrank, setSelectedRank] = useState('')
    const [metaCrownAmbassadorCount, setMetaCrownAmbassadorCount] = useState(0)
    const [metaAmbassadorCount, setMetaAmbassadorCount] = useState(0)
    const [metaUniversalCrownAmbassadorCount, setMetaUniversalCrownAmbassadorCount] = useState(0)



    console.log(id)

    const get_fund = async () => {
        try {
            const data = await gerUserRank(token);
            setGetRank(data.data);

        } catch (error) {
            toast.error('Failed to fetch Rank Limit.');
        }
    };

    useEffect(() => {
        get_fund()
    }, [])



    const handleSettingPage = async (e) => {

        e.preventDefault();

        const rankId = document.querySelector("#rank").value;
        if (rankId == '') {
            toast.error(`Please Select The Rank!`)
        }

        const submitData = {
            rank: rankId,
            selfstaking: selfstaking,
            firstpool: firstpool,
            secondpool: secondpool,
            thirdpool: thirdpool,
            metafounderCount: metafoundercount,
            metaStarCount: metastartcount,
            metaRoyalCount: metaroyalcount,
            metaPrimeCount: metaprimecount,
            matchrankid: rankId,
            metaCrownAmbassadorCount: metaCrownAmbassadorCount,
            metaUniversalCrownAmbassadorCount: metaUniversalCrownAmbassadorCount,
            metaAmbassadorCount : metaAmbassadorCount


        }
        try {
            const data = await addUserRankCondition(submitData, token)
            console.log(data)
            if (data.status == true) {
                toast.success(data.message)
                setInterval(() => {
                    window.location.reload();
                }, 2000)
            } else {
                toast.error(data.response.data.message)
            }

        } catch (error) {
            toast.error(error.response.data.message);
        }
    };


    const getSingleRank = async () => {
        try {
            const data = await singleUserRankCondition(id, token);

            setSelfStaking(data.data.selfstaking)
            setFirstPool(data.data.firstpool)
            setSecondPool(data.data.secondpool)
            setThirdPool(data.data.thirdpool)
            setMetafoundercount(data.data.metafounderCount)
            setMetastartcount(data.data.metaStarCount)
            setMetaroyalcount(data.data.metaRoyalCount)
            setMetaprimecount(data.data.metaPrimeCount)
            setSelectedRank(data.data.matchrankid)
            setMetaCrownAmbassadorCount(data.data.metaCrownAmbassadorCount)
            setMetaUniversalCrownAmbassadorCount(data.data.metaUniversalCrownAmbassadorCount)
            setMetaAmbassadorCount(data.data.metaAmbassadorCount)


        } catch (error) {
            toast.error('Failed to fetch Rank Limit.');
        }
    };


    useEffect(() => {
        if (id) {
            getSingleRank();
        } else {
            setSelfStaking('')
            setFirstPool('')
            setSecondPool('')
            setThirdPool('')
            setMetafoundercount('')
            setMetastartcount('')
            setMetaroyalcount('')
            setMetaprimecount('')
            setSelectedRank('')
            setMetaCrownAmbassadorCount('')
            setMetaUniversalCrownAmbassadorCount('')
            setMetaAmbassadorCount('')
        }

    }, [id])

    const handleUserRankUpdate = async () => {
        const rankId = document.querySelector("#rank").value;
        const submitData = {
            rank: rankId,
            selfstaking: selfstaking,
            firstpool: firstpool,
            secondpool: secondpool,
            thirdpool: thirdpool,
            metafounderCount: metafoundercount,
            metaStarCount: metastartcount,
            metaRoyalCount: metaroyalcount,
            metaPrimeCount: metaprimecount,
            matchrankid: rankId,
            metaCrownAmbassadorCount: metaCrownAmbassadorCount,
            metaUniversalCrownAmbassadorCount: metaUniversalCrownAmbassadorCount,
            metaAmbassadorCount : metaAmbassadorCount

        }
        try {
            const data = await updateUserRankCondition(id, submitData, token)
            console.log(data)
            if (data.status == true) {
                toast.success(data.message)
                setInterval(() => {
                    window.location.reload();
                }, 2000)
            } else {
                toast.error(data.response.data.message)
            }

        } catch (error) {
            toast.error(error.response.data.message);
        }

    }



    return (
        <div className="col-lg-4">
            <div className="card mb-3">
                <ToastContainer />
                <div className="card-header pt-2">
                    <ul className="nav nav-tabs card-header-tabs" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className='nav-link active'

                            >
                                Rank Condition
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="tab-content">
                    <div className="tab-pane fade active show card-body" role="tabpanel">


                        <div className="row g-3">

                            <div className="col-md-12">
                                <label className="form-label">
                                    Rank
                                </label>
                                <select className='form-control' id="rank">
                                    <option>Select Rank</option>
                                    {
                                        getrank?.map((items, i) => {
                                            return (
                                                <>
                                                    {
                                                        selectedrank == items?._id ? <option value={items?._id} selected >{items?.name}</option> : <option value={items?._id}>{items?.name}</option>
                                                    }
                                                </>

                                            )
                                        })
                                    }

                                </select>


                            </div>


                            <div className="col-md-12">
                                <label className="form-label">
                                    Self Staking
                                </label>
                                <input
                                    type='number'
                                    className="form-control"
                                    name='symbol'
                                    value={selfstaking}
                                    onChange={(e) => setSelfStaking(e.target.value)}

                                />
                            </div>


                            <div className="col-md-12">
                                <label className="form-label">
                                    First Pool Amount
                                </label>
                                <input
                                    type='number'
                                    className="form-control"
                                    name='symbol'
                                    value={firstpool}
                                    onChange={(e) => setFirstPool(e.target.value)}

                                />

                            </div>
                            <div className="col-md-12">
                                <label className="form-label">
                                    Second Pool Amount
                                </label>
                                <input
                                    type='number'
                                    className="form-control"
                                    name='symbol'
                                    value={secondpool}
                                    onChange={(e) => setSecondPool(e.target.value)}

                                />

                            </div>

                            <div className="col-md-12">
                                <label className="form-label">
                                    Third Pool Amount
                                </label>
                                <input
                                    type='number'
                                    className="form-control"
                                    name='symbol'
                                    value={thirdpool}
                                    onChange={(e) => setThirdPool(e.target.value)}

                                />

                            </div>
                            <div className="col-md-12">
                                <label className="form-label">
                                    Minimum Meta Founder
                                </label>
                                <input
                                    type='number'
                                    className="form-control"
                                    name='symbol'
                                    value={metafoundercount}
                                    onChange={(e) => setMetafoundercount(e.target.value)}

                                />

                            </div>

                            <div className="col-md-12">
                                <label className="form-label">
                                Minimum Meta Star
                                </label>
                                <input
                                    type='number'
                                    className="form-control"
                                    name='symbol'
                                    value={metastartcount}
                                    onChange={(e) => setMetastartcount(e.target.value)}

                                />

                            </div>
                            <div className="col-md-12">
                                <label className="form-label">
                                Minimum Meta Royal
                                </label>
                                <input
                                    type='number'
                                    className="form-control"
                                    name='symbol'
                                    value={metaroyalcount}
                                    onChange={(e) => setMetaroyalcount(e.target.value)}

                                />

                            </div>
                            <div className="col-md-12">
                                <label className="form-label">
                                Minimum Meta Prime
                                </label>
                                <input
                                    type='number'
                                    className="form-control"
                                    name='symbol'
                                    value={metaprimecount}
                                    onChange={(e) => setMetaprimecount(e.target.value)}

                                />

                            </div>
                            <div className="col-md-12">
                                <label className="form-label">
                                Minimum Meta  Ambassador
                                </label>
                                <input
                                    type='number'
                                    className="form-control"
                                    name='symbol'
                                    value={metaAmbassadorCount}
                                    onChange={(e) => setMetaAmbassadorCount(e.target.value)}

                                />

                            </div>
                            <div className="col-md-12">
                                <label className="form-label">
                                Minimum Meta Crown Ambassador
                                </label>
                                <input
                                    type='number'
                                    className="form-control"
                                    name='symbol'
                                    value={metaCrownAmbassadorCount}
                                    onChange={(e) => setMetaCrownAmbassadorCount(e.target.value)}

                                />

                            </div>
                            <div className="col-md-12">
                                <label className="form-label">
                                Minimum Meta Universal Crown Ambassador Club
                                </label>
                                <input
                                    type='number'
                                    className="form-control"
                                    name='symbol'
                                    value={metaUniversalCrownAmbassadorCount}
                                    onChange={(e) => setMetaUniversalCrownAmbassadorCount(e.target.value)}

                                />

                            </div>



                        </div>


                        <div className="pt-4">
                            <button type="submit" className="btn btn-primary me-sm-3 me-1 waves-effect waves-light" onClick={id ? handleUserRankUpdate : handleSettingPage}>
                                Submit
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default RankConditionForm;
