import React from 'react'
import RankListComponent from './RankListComponent'
import RankForm from './RankForm'

export default function RankPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <RankForm />
                <RankListComponent />
            </div>
        </div>
    )
}
