import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { add_fund_user, add_user_rank, find_fund_user, singleUserRank, updateUserRank } from '../../common/Api';
import { useParams } from 'react-router-dom';


const RankForm = () => {

    const params = useParams()

    const { id } = params;
    const { token } = useSelector((state) => state.user);

    const [name, setName] = useState('')
    const [amount, setAmount] = useState(null)
    const [rank, setRank] = useState('')
    const [ranks, setRanks] = useState({})

    console.log(id)

    const handleSettingPage = async (e) => {

        e.preventDefault();


        if (name == '') {
            return toast.error('Please enter The name of rank')
        }
        if (amount == '') {
            return toast.error('Please enter The amount of rank')
        }
        if (rank == '') {
            return toast.error('Please enter The rank')
        }

        const submitData = {
            name: name,
            limitamount: amount,
            number: rank

        }
        try {
            const data = await add_user_rank(submitData, token)
            console.log(data)
            if (data.status == true) {
                toast.success(data.message)
                setInterval(() => {
                    window.location.reload();
                }, 2000)
            } else {
                toast.error(data.response.data.message)
            }

        } catch (error) {
            toast.error(error.response.data.message);
        }
    };


    const getSingleRank = async () => {
        try {
            const data = await singleUserRank(id, token);
            setName(data.data.name)
            setAmount(data.data.limitamount)
            setRank(data.data.number)

        } catch (error) {
            toast.error('Failed to fetch Rank Limit.');
        }
    };


    useEffect(() => {
        if (id) {
            getSingleRank();
        }else{
            setName('')
            setAmount('')
            setRank('')
        }
    }, [id])

    const handleUserRankUpdate = async() => {
        const submitData = {
            name: name,
            limitamount: amount,
            number: rank

        }
        try {
            const data = await updateUserRank(id , submitData, token)
            console.log(data)
            if (data.status == true) {
                toast.success(data.message)
                setInterval(() => {
                    window.location.reload();
                }, 2000)
            } else {
                toast.error(data.response.data.message)
            }

        } catch (error) {
            toast.error(error.response.data.message);
        }

    }



    return (
        <div className="col-lg-4">
            <div className="card mb-3">
                <ToastContainer />
                <div className="card-header pt-2">
                    <ul className="nav nav-tabs card-header-tabs" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className='nav-link active'

                            >
                                Rank
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="tab-content">
                    <div className="tab-pane fade active show card-body" role="tabpanel">


                        <div className="row g-3">

                            <div className="col-md-12">
                                <label className="form-label">
                                    Name
                                </label>
                                <div className="same d-flex">
                                    <input
                                        type='email'
                                        className="form-control"
                                        name='email'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}

                                    />

                                </div>

                            </div>


                            <div className="col-md-12">
                                <label className="form-label">
                                    Amount
                                </label>
                                <input
                                    type='number'
                                    className="form-control"
                                    name='symbol'
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}

                                />
                            </div>


                            <div className="col-md-12">
                                <label className="form-label">
                                    Rank
                                </label>
                                <input
                                    type='number'
                                    className="form-control"
                                    name='symbol'
                                    value={rank}
                                    onChange={(e) => setRank(e.target.value)}

                                />

                            </div>



                        </div>


                        <div className="pt-4">
                            <button type="submit" className="btn btn-primary me-sm-3 me-1 waves-effect waves-light" onClick={id ? handleUserRankUpdate : handleSettingPage}>
                                Submit
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default RankForm;
