// LineChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';

ChartJS.register(...registerables);
ChartJS.defaults.font.size = 8;

const OnlyAffiliateChart = ({ datas , color }) => {
    // Check if datas is provided
    // if (!datas || datas.length === 0) {
    //     return <div>No data available</div>;
    // }

    // Prepare your data
    const labels = datas.map(data => {
        const date = new Date(data.creditDate);
        return `${date.getDate()}/${date.getMonth() + 1}`; // Format as "DD/MM"
    });
    const amounts = datas.map(data => data.actualaffiliate); // Assuming amount is a property in your data

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Credit Amounts',
                data: amounts,
                backgroundColor: `${color}`, // Area fill color
                // borderColor: 'rgba(75, 192, 192, 1)', // Line color
                borderWidth: 2,
                fill: true, // Fill the area under the line
                tension: 0.4, // Smoothing the line
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Credit Dates',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Amounts',
                },
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    font: {
                        size: 11
                    }
                }
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
    };

    return (
        <div className='line-chartDas'>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default OnlyAffiliateChart;
