import React, { useState, useEffect } from "react";
import MywalletComponent from "./MywalletComponent";
import WalletTabComponent from "./WalletTabComponent";

export default function WalletPageComponent() {
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const dateOptions = { weekday: "short", month: "short", day: "numeric" };
      const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };

      setCurrentDate(now.toLocaleDateString(undefined, dateOptions));
      setCurrentTime(now.toLocaleTimeString(undefined, timeOptions));
    };

    updateDateTime();
    const intervalId = setInterval(updateDateTime, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  return (
    <div className="my_wallet mobile_show">
      <div className="time_date">
        <span className="date">{currentDate}</span>
        <span className="time">
          {"  "}
          {currentTime}
        </span>
      </div>
      <h2 class="heading mb-4 text-white">My Wallet</h2>
      <MywalletComponent />
      <WalletTabComponent />
    </div>
  );
}
