import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify'; // Optional for notifications
import { useSelector } from 'react-redux';
import { deleteUserRank, deleteUserRankCondition, gerUserRank, gerUserRankCondition, get_fund_history, userRankStatusUpdate } from '../../common/Api';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';

const RankConditionList = () => {

    const navigate = useNavigate()
    const { token } = useSelector((state) => state.user);
    const [fund, setFund] = useState([]);
    const [filteredCategory, setFilteredCategory] = useState([]);
    const [search, setSearch] = useState('');
    const [show, setShow] = useState(false);
    const [selectedFund, setSelectedFund] = useState({});

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const get_fund = async () => {
        try {
            const data = await gerUserRankCondition(token);
            console.log(data)
            setFund(data.data);
            setFilteredCategory(data.data);
        } catch (error) {
            toast.error('Failed to fetch Rank Limit.');
        }
    };

    useEffect(() => {
        get_fund();
    }, [token]);

    useEffect(() => {
        const result = fund.filter(user =>
            user?.rank?.name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredCategory(result);
    }, [search, fund]);

    const handleFundView = (fundData) => {
        setSelectedFund(fundData);
        setShow(true);
    };





    const handledelete = async (id) => {
        const confirm = window.confirm('Are you sure to delete this Rank?');
        if (confirm) {
            try {
                const data = await deleteUserRankCondition(id, token)
                if (data.status == true) {
                    toast.success(data.message)
                    get_fund();
                }
            } catch (error) {
                toast.error(error.message);
            }
        }
    };

    const handleEdit = async (id) => {
        navigate(`/admin/user-rank/${id}`);
    };



    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Rank',
            selector: row => row?.rank?.name,
            sortable: true,
        },
        {
            name: 'Self Staking',
            selector: row => row?.selfstaking,
            sortable: true,
        },
        {
            name: 'First Pool Amount',
            selector: row => row?.firstpool,
            sortable: true,
        },
        {
            name: 'Second Pool Amount',
            selector: row => row?.secondpool,
            sortable: true,
        },
        {
            name: 'Third Pool Amount',
            selector: row => row?.thirdpool,
            sortable: true,
        },


        {
            name: 'Actions',
            cell: row => (
                <>
                    <Link to={`/admin/user-rank-condition/${row._id}`}>  <i className="fas fa-edit me-2" style={{ cursor: "pointer", color: "green" }}></i></Link>
                    <i className="fas fa-trash me-2" style={{ cursor: "pointer", color: "red" }} onClick={() => handledelete(row._id)}></i>
                </>
            ),
        },
    ];

    return (
        <div className='col-lg-8'>
            <div className="card mb-3">
                <h5 className="card-header">Rank Condition List</h5>
                <div className="card-body">
                    <div className="mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Name"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredCategory}
                        pagination
                        highlightOnHover
                        striped
                    />
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><h6>Fund History {selectedFund.email}</h6></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className='table border table-responsive'>
                        <tbody>
                            {Object.entries(selectedFund).map(([key, value]) => (
                                key !== 'amount' && key !== 'type' ? (
                                    <tr key={key}>
                                        <td><b>{capitalizeFirstLetter(key)}</b></td>
                                        <td>{value}</td>
                                    </tr>
                                ) : null
                            ))}
                            <tr>
                                <td><b>Amount</b></td>
                                <td>
                                    {selectedFund.amount}
                                    <i
                                        className={`menu-icon tf-icons ti ${selectedFund.type === 'add' ? 'ti-arrow-up' : 'ti-arrow-down'}`}
                                        style={{
                                            fontSize: '13px',
                                            fontWeight: 'bold',
                                            color: selectedFund.type === 'add' ? '#28c76f' : '#ff9f43',
                                        }}
                                    ></i>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Type</b></td>
                                <td>
                                    <Badge bg={selectedFund.type === 'add' ? 'success' : 'warning'}>
                                        {selectedFund.type}
                                    </Badge>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

// Utility function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export default RankConditionList;
