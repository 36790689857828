import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LiveClock from 'react-live-clock';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  btuser_single_data_get,
  api_get_setting_data,
  get_user_dashboard_stat,
  userMainBalanceGet
} from "../../../common/Api";
import { useSelector } from "react-redux";
import { url, Image } from "../../../Components/Common/Url";

const rankCritica = [
  { id: 1, name: "Basic Club" },
  { id: 2, name: "Meta Founder Club" },
  { id: 3, name: "Meta Star Club" },
  { id: 4, name: "Meta Royal Club" },
  { id: 5, name: "Meta Prime Club" },
  { id: 6, name: "Meta Ambassador Club" },
];

export default function UserProfile() {
  const { user, token } = useSelector((state) => state.user);
  const [users, setUsers] = useState({});
  const [rank, setRank] = useState({});
  const [nextRank, setNextRank] = useState({});
  const [textToCopy, setTextToCopy] = useState("afewfas");
  const [metaBTPrice, setMetaBTPrice] = useState(0.0);
  const [mainbalance, setMainBalance] = useState(0.0);
  const [totalDeposit, setTotalDeposit] = useState("");
  const [totalWithdrawal, setTotalWithdrawal] = useState("");
  const [totalRewards, setTotalRewards] = useState("");
  const [depositbalance, setDepositBalance] = useState(0.0);
  const [isSkelton, setIsSkelton] = useState(true);

  const copyText = (code) => {
    navigator.clipboard.writeText(code);
    alert("Copied the text: " + code);
    // Reset status after 2 seconds
  };

  const user_dashboard = async () => {
    try {
      const response = await get_user_dashboard_stat(user._id, token);
     // console.log(response)
      setDepositBalance(response?.depositbalance)
      setTotalDeposit(response?.totalDeposit);
      setTotalWithdrawal(response?.withbalance);
      setTotalRewards(
        (response?.tstake?.totalAffiliateAmount || 0) +
          (response?.tstake?.totalStakingAmount || 0) +
          (response?.totalRoyalityAmount || 0) +
          (response?.totalReferrakAmount || 0)
      );
      setIsSkelton(false);
    } catch (error) {
      //toast.error(error);
    }
  };

  const getSettingData = async () => {
    try {
      const data = await api_get_setting_data(token);
     // console.log(data.data);
      setMetaBTPrice(data.data?.usd_price);
    } catch (error) {
      //toast.error("Failed to fetch settings data.");
    }
  };

  const btGetSingleUserData = async () => {
    try {
      const data = await btuser_single_data_get(user._id, token);
      //console.log(data)
      const ranks = rankCritica.find((rank) => rank.id === data.data.btRank);
      // console.log(ranks)
      setRank(ranks);

      const nextRank1 = rankCritica.find(
        (rank) => rank.id === data.data.btRank + 1
      );
      setNextRank(nextRank1);
      setUsers(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const user_get_main_balance = async () => {
    try {
      const data = await userMainBalanceGet(user._id)
      //console.log(data)
      setMainBalance(data.balance)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    user_dashboard();
    getSettingData();
    btGetSingleUserData();
    user_get_main_balance();
  }, []);

  const formatAmount = (amount) => {
    if (amount < 0) return "0.00";
    const truncatedAmount = Math.floor(amount * 100) / 100;
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(truncatedAmount);
  };



  return (
    <div className="container-fluid px-2 px-lg-0 px-md-0 pb-5 pb-lg-0 pb-md-0 h-100">
      <div className="profile">
        <div className="timeDiv d-block d-lg-none d-md-none pb-3">
          <LiveClock
            format={'dddd, MMMM Do, YYYY, h:mm:ss A'}
            ticking={true}
            timezone={'Asia/Kolkata'} // IST timezone
          />
        </div>
        <div className="profile-logo">
          <img
            src={
              user?.profileImg
                ? `${Image}/uploads/${user?.profileImg}`
                : `${Image}/uploads/default-avatar.jpg`
            }
          />
          <div className="profile_name">
            <h6 className="mb-0 pb-1 fw-bold" style={{ color: "#C4C4C4" }}>
              Hi {users?.username}
            </h6>
            {/* <p>{users?.email} ({rank?.name || '-NA-'})</p> */}
            <p className="mb-0" style={{ color: "#C4C4C4" }}>
              {users?.email}
            </p>
          </div>
        </div>
        <div className="balance_card">
          <div className="balance_icon d-flex align-items-center gap-2">
            <i className="fa-brands fa-stack-exchange"></i>
            <span
              className="ms-1 text-white fw-bold"
              style={{ textTransform: "uppercase" }}
            >
              Main Balance
            </span>
          </div>
          <div className="balance d-flex mt-3 mb-0 gap-2 align-items-end flex-wrap">
            <h4
              style={{ fontSize: "30px", color: "#ACA4EB" }}
              className="mb-0 fw-semibold text-wrap"
            >
              {isSkelton ? (
                <Skeleton height={30} />
              ) : (
                formatAmount(mainbalance || 0)
              )}
            </h4>
            <p className="mb-0 pb-1">BTMETA</p>
          </div>
          <span className="d-flex ">
          {isSkelton ? (
              <Skeleton inline={true} height={16} />
            ) : (
              formatAmount(mainbalance * metaBTPrice || 0)
            )}{" "} USDT
          </span>
        </div>

        <div className="balance_card">
          <div className="balance_icon d-flex align-items-center gap-2">
            <i className="fa-brands fa-stack-exchange"></i>
            <span
              className="ms-1 text-white fw-bold"
              style={{ textTransform: "uppercase" }}
            >
              Total Deposit
            </span>
          </div>
          <div className="balance d-flex mt-3 mb-0 gap-2 align-items-end flex-wrap">
            <h4
              style={{ fontSize: "30px", color: "#ACA4EB" }}
              className="mb-0 fw-semibold text-wrap"
            >
              {isSkelton ? <Skeleton /> : formatAmount(totalDeposit || 0)}
            </h4>
            <p className="mb-0 pb-1">BTMETA</p>
          </div>
          <span className="d-flex ">
          {isSkelton ? (
              <Skeleton height={16} />
            ) : (
              formatAmount(totalDeposit * metaBTPrice || 0)
            )}{" "} USDT
          </span>
        </div>

        {/* <div className="balance_card">
          <div className="balance_icon d-flex align-items-center gap-2">
            <i className="fa-brands fa-stack-exchange"></i>
            <span
              className="ms-1 text-white fw-bold"
              style={{ textTransform: "uppercase" }}
            >
             Deposit Wallet
            </span>
          </div>
          <div className="balance d-flex mt-3 mb-0 gap-2 align-items-end">
            <h4
              style={{ fontSize: "30px", color: "#ACA4EB" }}
              className="mb-0 fw-semibold text-wrap"
            >
              {formatAmount(depositbalance)}
            </h4>
            <p className="mb-0 pb-1">BTMETA</p>
          </div>
          <span className="d-flex ">
            {formatAmount(totalDeposit * metaBTPrice)} USDT
          </span>
        </div> */}

        <div className="balance_card">
          <div className="balance_icon d-flex align-items-center gap-2">
            <i className="fa-brands fa-stack-exchange"></i>
            <span
              className="ms-1 text-white fw-bold"
              style={{ textTransform: "uppercase" }}
            >
              Total Withdrawal
            </span>
          </div>
          <div className="balance d-flex mt-3 mb-0 gap-2 align-items-end flex-wrap">
            <h4
              style={{ fontSize: "30px", color: "#ACA4EB" }}
              className="mb-0 fw-semibold text-wrap"
            >
              {isSkelton ? (
                <Skeleton />
              ) : (
                formatAmount(Math.abs(totalWithdrawal))
              )}
            </h4>
            <p className="mb-0 pb-1">BTMETA</p>
          </div>
          <span className="d-flex ">
          {isSkelton ? (
              <Skeleton />
            ) : (
              formatAmount(Math.abs(totalWithdrawal * metaBTPrice))
            )}{" "} USDT
          </span>
        </div>

        <div className="balance_card">
          <div className="balance_icon d-flex align-items-center gap-2">
            <i className="fa-brands fa-stack-exchange"></i>
            <span
              className="ms-1 text-white fw-bold"
              style={{ textTransform: "uppercase" }}
            >
              Total Rewards
            </span>
          </div>
          <div className="balance d-flex mt-3 mb-0 gap-2 align-items-end flex-wrap">
            <h4
              style={{ fontSize: "30px", color: "#ACA4EB" }}
              className="mb-0 fw-semibold text-wrap"
            >
              {isSkelton ? (
                <Skeleton />
              ) : (
                formatAmount(Math.abs(totalRewards))
              )}
            </h4>
            <p className="mb-0 pb-1">BTMETA</p>
          </div>
          <span className="d-flex ">
            {isSkelton ? (
              <Skeleton />
            ) : (
              formatAmount(Math.abs(totalRewards * metaBTPrice))
            )}{" "}
            USDT
          </span>
        </div>

        <div className="balance_card">
          <div className="balance_icon d-flex align-items-center gap-2">
            <span
              className="text-white fw-bold"
              style={{ textTransform: "uppercase", fontSize: "20px" }}
            >
              Current Rank{" "}
            </span>
            {/* <span style={{ fontSize: "16px", color: '#ACA4EB' }}>Achieved</span> */}
          </div>
          <div class="rank-info mt-3">
            <div class="mb-1 rank-box">
              <h6 class="rank-name-top text-warning">{rank?.name || "-NA-"}</h6>
            </div>
            {/* <div class="mb-1 text-center rank-box">
                            <h6 class="rank-name-top text-warning">-NA-</h6>
                            <h6 class="rank-title text-white mb-0">Reward Income</h6>
                        </div> */}
          </div>
          <div class="rank-info1">
            <div class="mb-1 rank-box">
              <h6 class="rank-title text-white mb-0 pb-0">Next Rank :- </h6>
              <h6 class="rank-name1 mb-0" style={{ color: "#ACA4EB" }}>
                {nextRank?.name}
              </h6>
            </div>
            {/* <div class="mb-1 text-center rank-box">
                            <h6 class="rank-title text-white mb-0 pb-0">Reward Income :- </h6>
                            <h6 class="rank-name1 mb-0" style={{ color: '#ACA4EB' }}>$700</h6>
                        </div> */}
          </div>
        </div>
        {/* <div className="balance_card">
                    <div className="balance_icon">
                        <span className="text-white fw-bold" style={{ textTransform: "uppercase", fontSize: '20px' }}>Rank Maintain Criteria </span>
                        <div className='maintain-values py-3'>
                            <div class="form-check d-flex align-items-center gap-3 mb-3">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                <label class="form-check-label mb-0" for="flexCheckDefault">
                                    Default checkbox
                                </label>
                            </div>
                            <div class="form-check d-flex align-items-center gap-3 mb-3">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label class="form-check-label mb-0" for="flexCheckDefault">
                                    Default checkbox
                                </label>
                            </div>
                            <div class="form-check d-flex align-items-center gap-3 mb-3">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label class="form-check-label mb-0" for="flexCheckDefault">
                                    Default checkbox
                                </label>
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="mt-2">
          <h6 className="text-start text-white">Refer & Enjoy Rewards</h6>
          <p className="text-start text-light">
            You'll get rewards against your referral acticities
          </p>
        </div>
        <div className="referal_fields ">
          <label>Referral Code:</label>
          <div className="input-box">
            <input
              type="text"
              placeholder={users?.btucode}
              style={{ paddingRight: "30px", position: "relative" }}
              readOnly={true}
            />
            <i
              className="fa-regular fa-copy"
              onClick={() => copyText(users?.btucode)}
            ></i>
          </div>
        </div>
        <div className="referal_fields mt-3">
          <label>Referral Link:</label>
          <div className="input-box">
            <input
              type="text"
              placeholder={`https://btsmart.ai/register/${user?.btucode}`}
              style={{ paddingRight: "30px", position: "relative" }}
              readOnly={true}
            />
            <i
              className="fa-regular fa-copy"
              onClick={() =>
                copyText(
                  `https://btsmart.ai/register/${user?.btucode}`
                )
              }
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
}
