import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { delete_user, get_paginated_user, update_user_status, user_dashboard_login } from '../../common/Api';
import Badge from 'react-bootstrap/Badge';
import { userdashboardLogin } from '../../Services/UserSlices';
import Swal from 'sweetalert2';

const UserListNewComponent = () => {
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.user);
    const navigation = useNavigate();
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
    const [totalUsers, setTotalUsers] = useState(0); // State to hold total number of users

    const get_users = async (currentPage, searchTerm) => {
        try {
            const data = await get_paginated_user(token, currentPage, rowsPerPage, searchTerm);
            setUsers(data.data);
            setTotalUsers(data.total); // Update the total number of users from response
        } catch (error) {
            toast.error('Failed to fetch users.');
        }
    };

    useEffect(() => {
        get_users(page, search);
    }, [page, rowsPerPage, search]); // Add rowsPerPage to dependencies

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            get_users(page, search);
        }, 300); // Debouncing the search input

        return () => clearTimeout(debounceTimer);
    }, [search]);

    const handleStatus = async (id, status) => {
        try {
            const data = await update_user_status(id, status, token);
            if (data.status) {
                toast.success(data.message);
                get_users(page, search);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleDelete = async (id) => {
        const confirm = window.confirm('Are you sure to delete this User?');
        if (confirm) {
            try {
                const data = await delete_user(id, token);
                if (data.status) {
                    toast.success(data.message);
                    get_users(page, search);
                }
            } catch (error) {
                toast.error(error.message);
            }
        }
    };


    const handleUserLogin = async (email) => {

        try {
            const submitData = {
                email: email
            }
            //const data =  await user_dashboard_login(token, submitData)
            const resultAction = await dispatch(userdashboardLogin(submitData));
            console.log(resultAction)
            if (userdashboardLogin.fulfilled.match(resultAction)) {
                console.log(resultAction);
                toast.success("Login Successful");
                localStorage.setItem("refresh", "refresh");
                    localStorage.setItem("admin", "true");
                    window.open("/user/dashboard", "_blank"); // Open in a new tab
            } else {
                return Swal.fire({
                    icon: "error",
                    title: "",
                    text: resultAction.payload || "Login failed",
                });
                //setMsg(resultAction.payload || "Login failed");
                //toast.error(resultAction.payload || 'Login failed');
            }

        } catch (error) {
            console.log(error)
        }

    }

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1 + (page - 1) * rowsPerPage, // Adjust index for pagination
            sortable: true,
        },
        {
            name: 'BTCash Code',
            selector: row => row.btucode,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: row => row.number, // Fixed typo from 'numnber' to 'number'
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => (
                <Badge bg="warning">
                    {row.isAdmin}
                </Badge>
            ),
            sortable: true,
        },
        {
            name: 'Balance',
            selector: row => <>$ {row.btbalance}</>,
            sortable: true,
        },
        {
            name: 'Roles',
            selector: row => (
                <Link to={`/admin/users-roles/${row._id}`}>
                    <Badge bg="success">Roles & Permission</Badge>
                </Link>
            ),
            sortable: true,
        },
        {
            name: 'Login',
            selector: row => (
                <Link onClick={() => handleUserLogin(row.email)}>
                    <Badge bg="danger">Login</Badge>
                </Link>
            ),
            sortable: true,
        },
        {
            name: 'Status',
            cell: row => (
                <label className="switch">
                    <input
                        type="checkbox"
                        className="switch-input"
                        checked={row.status === 1}
                        onChange={() => handleStatus(row._id, row.status)}
                    />
                    <span className="switch-toggle-slider">
                        {row.status === 1 ? (
                            <span className="switch-on">
                                <i className="ti ti-check"></i>
                            </span>
                        ) : (
                            <span className="switch-off">
                                <i className="ti ti-x"></i>
                            </span>
                        )}
                    </span>
                </label>
            ),
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                    <Link to={`/admin/add-user/${row._id}`}>
                        <i className="fas fa-edit me-2" style={{ cursor: "pointer", color: "green" }}></i>
                    </Link>
                    <i className="fas fa-trash me-2" style={{ cursor: "pointer", color: "red" }} onClick={() => handleDelete(row._id)}></i>
                </>
            ),
        },
    ];

    return (
        <div className='col-lg-12'>
            <div className="mb-3">
                <h4 className="card-header pb-4 text-white">User List</h4>
                <div className="card-body">
                    <div className="mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by name, email, type"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>

                    <DataTable
                        columns={columns}
                        data={users}
                        pagination
                        paginationServer // Enable server-side pagination
                        paginationTotalRows={totalUsers} // Use totalUsers for correct pagination
                        onChangePage={newPage => setPage(newPage)} // Handle page change
                        onChangeRowsPerPage={newRowsPerPage => {
                            setRowsPerPage(newRowsPerPage);
                            setPage(1); // Reset to first page on rows per page change
                        }}
                        highlightOnHover
                        striped
                        paginationPerPage={rowsPerPage} // Default rows per page
                        className='userListTable'
                    />
                </div>
            </div>
        </div>
    );
};

export default UserListNewComponent;
