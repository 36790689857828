import React, { useEffect, useState } from 'react';
import { daily_reward_update, get_testing_dashboard, get_user_dashboard_stat } from '../../../common/Api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import UserProfile from './UserProfile';
import LineChart from './Char';
import TwoLineChart from './TwoLineChart';
import BarChart from './BarChart';
import mapImg from './map.png'
import { useNavigate } from 'react-router-dom';
import OnlyStakingChart from './OnlyStakingDataShow';
import OnlyAffiliateChart from './onlyAffiliateDataShow';
import popupImage from './popup.jpg'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Loader from '../../../PageLoader/Loader';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";


export default function TestingDashboardPage() {
  const navigate = useNavigate()
  const { user, token } = useSelector((state) => state.user);
  const refesh = localStorage.getItem('refresh')
  const [todaystake, setTodaSTake] = useState(0);
  const [todayaffiliate, setTodayAffiliate] = useState(0);
  const [totalStake, setTotalStake] = useState(0);
  const [totalaffiliate, setTotalAffiliate] = useState(0);

  const [todaystakedata, setTodayStakeData] = useState([]);
  const [todayaffilaiteData, setTodayAffilaiteData] = useState([]);
  const [totalStakingdata, setTotalStakingData] = useState([]);
  const [totalaffiliatedata, setTotalAffiliateData] = useState([]);
  const [totalDeposit, setTotalDeposit] = useState("");
  const [totalWithdrawal, setTotalWithdrawal] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isSkelton, setIsSkelton] = useState(true);
  


  //new use state make
  const [newstakedata, setNewStakeData] = useState({});
  const [newstakedataarray, setNewStakeDataarray] = useState([]);



  const user_dashboard = async () => {
    try {
      const response = await get_user_dashboard_stat(user._id, token);
      //console.log('user total response ==> ', response);

      // Use slicing to limit data to the latest 7 entries
      setTodaSTake(response?.tstake?.totalAmount);
      setTodayAffiliate(response?.tstake?.todayAffiliateAmount);
      setTotalAffiliate(response?.tstake?.totalAffiliateAmount);
      setTotalStake(response?.tstake?.totalStakingAmount);

      // Slice the data arrays to get only the latest 7 entries
      setTodayStakeData(response?.tstake?.todayData?.slice(0, 7) || []);
      setTodayAffilaiteData(response?.tstake?.todayAffiliateData?.slice(0, 7) || []);
      setTotalStakingData(response?.tstake?.totalStakingData?.slice(0, 7) || []);
      setTotalAffiliateData(response?.tstake?.totalAffiliateData?.slice(0, 7) || []);
      setTotalDeposit(response?.totalDeposit);
      setTotalWithdrawal(response?.totalWithdrawal);
      setIsSkelton(false);

    } catch (error) {
      toast.error(error);
    }
  };


  const get_user_test_dashborad = async () => {
    try {
      const data = await get_testing_dashboard(user._id , token)
      //console.log('dashboard data', data)
      // setNewStakeData(data)
      setNewStakeData(data.todayearnings)
      setNewStakeDataarray(data.weekdata)
    } catch (error) {
      console.log(error)
    }
  }

  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    if (!sessionStorage.getItem('modalShown')) {
      // Set a timeout to show the modal after 3 seconds
      const timeoutId = setTimeout(() => {
        setShowModal(true);
        sessionStorage.setItem('modalShown', 'true'); // Set the flag in sessionStorage
      }, 3000);

      // Cleanup timeout on component unmount
      return () => clearTimeout(timeoutId);
    }

    return () => clearTimeout(timer);
    
  }, []);





  useEffect(() => {
    //console.log("Component mounted or updated");
    if (user._id && token) {
      if (refesh) {
        localStorage.removeItem('refresh')
        window.location.reload();
      }
      // upto_date_reward();
      user_dashboard();
      get_user_test_dashborad();
    } else {
      navigate('/')
    }

   

  }, [user._id, token]);

  const closeModal = () => {
    setShowModal(false);
  };

  // Function to format numbers to two decimal places
  const formatAmount = (amount) => {
    return parseFloat(amount).toFixed(2);
  };

  //console.log(refesh)


  //console.log('newstakedata' , newstakedataarray)

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (

        <section className="dashboard">
          <div className="container-fluid px-3 px-lg-4 px-md-4">
            <div className="row flex-lg-row flex-column-reverse dashboard_item">
              <div className="col-lg-9 pt-0 pt-lg-5 pt-md-5 pb-5">
                <div className="text-left">
                  <h3>Welcome to Dashboard</h3>
                  {/* <p>Hi, {user?.username}</p> */}
                </div>
                <div className="row gy-2 gx-2">
                  <div className="col-lg-6 col-md-12 col-12 h-100">
                    <div className="row gy-2 gx-2">
                      <div className="col-6">
                        <div className="staking-reward h-100">
                          <div className="staking-reward_logo d-flex align-items-center">
                            <i className="fa-solid fa-layer-group"></i>
                            <p>TODAY STAKING REWARD</p>
                          </div>
                          <div className="chart-container">
                            <div className="value-display">{isSkelton ? <Skeleton /> : formatAmount(newstakedata?.totalstaking || 0)} <span style={{ fontSize: "13px" }}>BTMETA</span></div>
                            {/* <div className="meta-display">BTMETA</div> */}
                            <OnlyStakingChart datas={newstakedataarray} color='rgb(8, 217, 154)' />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="staking-reward h-100">
                          <div className="staking-reward_logo d-flex align-items-center">
                            <i className="fa-solid fa-bullhorn"></i>
                            <p>TODAY AFFILIATE REWARD</p>
                          </div>
                          <div className="chart-container">
                            <div className="value-display">{isSkelton ? <Skeleton /> : formatAmount(newstakedata?.actualaffiliate || 0)} <span style={{ fontSize: "13px" }}>BTMETA</span></div>
                            {/* <div className="meta-display">BTMETA</div> */}
                            <OnlyAffiliateChart datas={newstakedataarray} color='rgb(237, 27, 3)' />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="staking-reward h-100">
                          <div className="staking-reward_logo d-flex align-items-center">
                            <i className="fa-solid fa-scale-balanced"></i>
                            <p>TOTAL STAKE REWARD</p>
                          </div>
                          <div className="chart-container">
                            <div className="value-display">{isSkelton ? <Skeleton /> : formatAmount(totalStake)} <span style={{ fontSize: "13px" }}>BTMETA</span></div>
                            {/* <div className="meta-display">BTMETA</div> */}
                            <OnlyStakingChart datas={newstakedataarray} color='rgb(8, 217, 154)' />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="staking-reward h-100">
                          <div className="staking-reward_logo d-flex align-items-center">
                            <i className="fa-solid fa-layer-group"></i>
                            <p>TOTAL AFFILIATE REWARD</p>
                          </div>
                          <div className="chart-container">
                            <div className="value-display">{isSkelton ? <Skeleton inline={true}/> : formatAmount(totalaffiliate)} <span style={{ fontSize: "13px" }}>BTMETA</span></div>
                            {/* <div className="meta-display">BTMETA</div> */}
                            <OnlyAffiliateChart datas={newstakedataarray} color='rgb(237, 27, 3)' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="staking-reward2 h-100">
                      <div className="chart-container2">
                        <div className="d-flex justify-content-between">
                          <p>Staking / Affiliate</p>
                        </div>
                        <TwoLineChart datas1={newstakedataarray} datas2={newstakedataarray} color='rgb(200, 98, 222)' />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="staking-reward2 h-100">
                      <div className="chart-container2">
                        <div className="d-flex justify-content-between">
                          <p>Staking / Affiliate</p>
                        </div>
                        <BarChart datas1={newstakedataarray} datas2={newstakedataarray} />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className="card staking-reward">
                      <div class="map-container">
                        <img src={mapImg} className='img-fluid' />
                        <div class="point venezuela tippy" title="Venezuela"></div>
                        <div class="point brasil tippy" title="Brasil"></div>
                        <div class="point argentina tippy" title="Argentina"></div>
                        <div class="point colombia tippy" title="Colombia"></div>
                        <div class="point panama tippy" title="Panamá"></div>
                        <div class="point mexico tippy" title="Mexico"></div>
                        <div class="point usa tippy" title="Estados Unidos"></div>
                        <div class="point arabia tippy" title="Arabia Saudi"></div>
                        <div class="point turquia tippy" title="Turquía"></div>
                        <div class="point rusia tippy" title="Rusia"></div>
                        <div class="point china tippy" title="China"></div>
                        <div class="point japon tippy" title="Japon"></div>
                        <div class="point australia tippy" title="Australia"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-12">
                <UserProfile user={user} totalDeposit={totalDeposit} totalWithdrawal={totalWithdrawal} />
              </div>
            </div>
          </div>
          <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <img src={popupImage} class="img-fluid" />
            </Modal.Body>
          </Modal>
        </section>
      )
    }

    </>

  );
}
