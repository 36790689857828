import React from 'react'
import RankCriteriaForm from './RankCriteriaForm'
import RankCriteriaList from './RankCriteriaList'


export default function RankCriteriaPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <RankCriteriaForm />
                <RankCriteriaList />
            </div>
        </div>
    )
}
