import React, { useEffect, useState } from "react";
import { getDirectUserReferal } from "../../../common/Api";
import { useSelector } from "react-redux";
import NewTree from "./NewTree";

export default function NewTreeComponent() {
  const { user, token } = useSelector((state) => state.user);
  const [expandedUser, setExpandedUser] = useState(null);

  const [users, setUsers] = useState([]);
  const [oneusers, setOneUsers] = useState([]);

  const getDirectReferalData = async () => {
    try {
      const data = await getDirectUserReferal(user.btucode, token);
      //console.log(data);
      setUsers(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDirectReferalData();
  }, []);

  const handleToggle = async (email, code) => {
    // If the clicked user is already expanded, collapse it; otherwise, expand it
    //alert(email)
    const data = await getDirectUserReferal(code, token);
    //console.log(data);
    setOneUsers(data.data);
    setExpandedUser(email);
  };

  return (
    <div className="container-fluid overflow-hidden">
      <div className="mt-2">
        <div className="row">
          <div className="col-lg-12" style={{ overflowX:"scroll"}}>
            {/* <div className="card m-2">
                        <ul>
                            {
                                users?.length > 0 ? <>
                                    {
                                        users?.map((items, i) => {
                                            return (
                                                <>
                                                    <li key={i} style={{ margin: "10px 10px", cursor: "pointer", listStyle: "none" }} onClick={() => handleToggle(items.email, items.btucode)}>
                                                        <i class="ti ti-arrow-down"></i>


                                                        {items?.email}

                                                        {expandedUser === items.email &&

                                                            <>

                                                                {
                                                                    oneusers?.length > 0 ? <>
                                                                        <ul>
                                                                            {
                                                                                oneusers.map((itemss, i) => {
                                                                                    return (

                                                                                        <li key={i} style={{ margin: "10px 10px", cursor: "pointer", listStyle: "none" }} onClick={() => handleToggle(items.email, items.btucode)}>

                                                                                            <i class="ti ti-arrow-down"></i>



                                                                                            {itemss?.email}
                                                                                        </li>



                                                                                    )
                                                                                })
                                                                            }

                                                                        </ul>

                                                                    </> : <>
                                                                        <ul>
                                                                            <li>No Data Found!</li>
                                                                        </ul>
                                                                    </>

                                                                }

                                                            </>
                                                        }
                                                    </li >

                                                </>

                                            )
                                        })
                                    }

                                </> : <></>
                            }


                        </ul>
                    </div> */}
            <NewTree />
          </div>
        </div>
      </div>
    </div>
  );
}
