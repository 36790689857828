import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { url, Image } from "../Components/Common/Url";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import ImageUpload from "./ImageUpload";
import FabIcon from "./FabIcon";
import { validateEmail, validatePhoneNumber } from "../common/Validations";
import { api_get_setting_data, update_global_setting } from "../common/Api";
import axios from "axios";
import { ReactCanvasPatternLock } from "react-canvas-pattern-lock";
import Swal from "sweetalert2";

const SettingsForm = () => {
  const { user, token } = useSelector((state) => state.user);
  const [pattern, setPattern] = useState([]);
  const [activeTab, setActiveTab] = useState("settingTab");
  const [isProcessing, setIsProcessing] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [secret, setSecret] = useState("");
  const [message, setMessage] = useState("");

  // Handle the pattern completion event
  const handlePatternComplete = (patternCode) => {
    setPattern(patternCode.join("")); // Store the pattern when completed
  };

  // Submit the pattern to the backend
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (pattern.length === 0) {
      return Swal.fire({
        icon: "error",
        title: "",
        text: "Please complete the pattern before submitting.",
      });
    }

    try {
      // Send the pattern to the backend (adjust API URL as needed)
      const response = await axios.post(
        `${url}/api/v1/user/update-pattern`,
        { pattern, id: user._id },
        {
          headers: { token },
        }
      );

      if (response.data.status) {
        return Swal.fire({
          icon: "success",
          title: "",
          text: "Pattern updated successfully!",
        });
      } else {
        return Swal.fire({
          icon: "error",
          title: "",
          text: "Failed to update pattern. Please try again.",
        });
      }
    } catch (err) {

      return Swal.fire({
        icon: "error",
        title: "",
        text: "Error updating pattern. Please try again later.",
      });
    }
  };

  const handleCronAction = async (url) => {
    setIsProcessing(true);
    try {
      await axios.get(url);
      alert("Cron executed successfully.");
    } catch (error) {
      alert("An error occurred while processing the cron.");
    } finally {
      setIsProcessing(false);
    }
  };

  const [formData, setFormData] = useState({
    title: "",
    image: "",
    number: "",
    email: "",
    address: "",
    key: "",
    flink: "",
    tlink: "",
    ilink: "",
    ylink: "",
    blink: "",
    fab: "",
    copyright: "",
    usd_price: 0.0,
    affiliate_transaction_amount: 0.0,
    affiliate_transaction_limit: 0,
    staking_transaction_amount: 0.0,
    staking_transaction_limit: 0,
    royalty_transaction_amount: 0.0,
    royalty_transaction_limit: 0,
    ft_affiliate_transaction_amount: 0.0,
    ft_affiliate_transaction_limit: 0,
    ft_staking_transaction_amount: 0.0,
    ft_staking_transaction_limit: 0,
    ft_royalty_transaction_amount: 0.0,
    ft_royalty_transaction_limit: 0,
    referral_transaction_amount: 0,
    referral_transaction_limit: 0,
    ft_referral_transaction_amount: 0,
    ft_referral_transaction_limit: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSettingPage = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      toast.error("Invalid Email format");
      return;
    }

    if (!validatePhoneNumber(formData.number)) {
      toast.error("Invalid Phone number");
      return;
    }

    const submitData = new FormData();
    for (const key in formData) {
      submitData.append(key, formData[key]);
    }

    try {
      const data = await update_global_setting(submitData, token);
      console.log("after send data", data);
      if (data.status == true) {
        if (activeTab == "tokenTab") {
          toast.success("Token price updated successfully");
        } else {
          toast.success(data.message);
        }

        // setInterval(() => {
        //   window.location.reload();
        // }, 2000);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getSettingData = async () => {
    try {
      const data = await api_get_setting_data(token);
      setFormData(data.data);
    } catch (error) {
      toast.error("Failed to fetch settings data.");
    }
  };

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    const fetchQrCode = async (email) => {
      try {
        const response = await axios.post(`${url}/api/generate-secret`, {
          email,
        });
        setQrCode(response.data.qrCodeDataURL);
        setSecret(response.data.secret);
      } catch (err) {
        console.error("Error fetching QR code:", err);
      }
    };

    fetchQrCode(user?.email);
    getSettingData();
  }, [activeTab]);

  //console.log('formdata ==> ',formData);

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col">
            <div className="staking-reward2  mb-3">
              <div className="card-header pt-2">
                <ul className="nav nav-tabs card-header-tabs" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className={`tablinks tab-link nav-item  ${
                        activeTab === "settingTab" ? "active" : ""
                      }`}
                      onClick={() => openTab("settingTab")}
                    >
                      Global Settings
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`tablinks tab-link nav-item  ${
                        activeTab === "tokenTab" ? "active" : ""
                      }`}
                      onClick={() => openTab("tokenTab")}
                    >
                      Token Price
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`tablinks tab-link nav-item  ${
                        activeTab === "transactionsLimit" ? "active" : ""
                      }`}
                      onClick={() => openTab("transactionsLimit")}
                    >
                      Transactions Limit
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`tablinks tab-link nav-item  ${
                        activeTab === "cronTab" ? "active" : ""
                      }`}
                      onClick={() => openTab("cronTab")}
                    >
                      Cron
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`tablinks tab-link nav-item  ${
                        activeTab === "gauthenticator" ? "active" : ""
                      }`}
                      onClick={() => openTab("gauthenticator")}
                    >
                      Google Authenticator
                    </button>
                  </li>
                </ul>
              </div>

              {/* Setting Tab */}
              <div id="settingTab" className="tab-content">
                {activeTab === "settingTab" && (
                  <div
                    className="tab-pane fade active show card-body"
                    role="tabpanel"
                  >
                    <form onSubmit={handleSettingPage}>
                      <div className="row g-3">
                        <div className="col-md-12">
                          <label className="form-label">Website Logo</label>{" "}
                          <br />
                          {formData?.image && (
                            <img
                              src={`${Image}/uploads/${formData?.image}`}
                              className="d-block  rounded mb-4"
                              width={"100px"}
                            />
                          )}
                          <ImageUpload
                            image={formData?.image}
                            setImage={setFormData}
                          />
                        </div>

                        <div className="col-md-12">
                          <label className="form-label">Fab icon</label>
                          <br />
                          {formData?.fab && (
                            <img
                              src={`${Image}/uploads/${formData?.fab}`}
                              className="d-block   rounded mb-4"
                              width={"100px"}
                            />
                          )}
                          <FabIcon image={formData?.fab} setFab={setFormData} />
                        </div>
                        {[
                          { label: "Title", name: "title", type: "text" },
                          {
                            label: "Wallet Address",
                            name: "address",
                            type: "text",
                          },
                          // { label: "Wallet Key", name: "key", type: "text" },
                          {
                            label: "Phone Number",
                            name: "number",
                            type: "text",
                          },
                          { label: "Email", name: "email", type: "text" },
                          {
                            label: "Facebook Link",
                            name: "flink",
                            type: "text",
                          },
                          {
                            label: "Twitter Link",
                            name: "tlink",
                            type: "text",
                          },
                          {
                            label: "Instagram Link",
                            name: "ilink",
                            type: "text",
                          },
                          {
                            label: "Youtube Link",
                            name: "ylink",
                            type: "text",
                          },
                          { label: "Blog Link", name: "blink", type: "text" },
                          {
                            label: "Copyright",
                            name: "copyright",
                            type: "text",
                          },
                        ].map((field, idx) => (
                          <>
                            <div key={idx} className="col-md-12">
                              <label className="form-label">
                                {field?.label}
                              </label>
                              <input
                                type={field?.type}
                                className="form-control"
                                name={field?.name}
                                value={
                                  field?.name === "key"
                                    ? formData[field?.name]?.replace(/./g, "*") // Mask the value with '*'
                                    : formData[field?.name]
                                }
                                onChange={handleInputChange}
                                dir={field?.dir}
                              />
                            </div>
                          </>
                        ))}
                      </div>

                      <div className="pt-4">
                        <button
                          type="submit"
                          className="border-0 rounded me-sm-3 me-1 waves-effect loginBtn addUserBTn"
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                )}
                {/* Token Tab Content*/}
                {activeTab === "tokenTab" && (
                  <div
                    className="tab-pane fade active show card-body"
                    role="tabpanel"
                  >
                    <form onSubmit={handleSettingPage}>
                      <div className="row g-3">
                        <div key="usd_price_1" className="col-md-12">
                          <label className="form-label">
                            MetaBT Price (In USDT)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="usd_price"
                            value={formData["usd_price"]}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="pt-4">
                        <button
                          type="submit"
                          className="border-0 rounded me-sm-3 me-1 waves-effect loginBtn addUserBTn"
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                )}
                {/* Transaction Limit content */}
                {activeTab === "transactionsLimit" && (
                  <div
                    className="tab-pane fade active show card-body"
                    role="tabpanel"
                  >
                    <div class="row">
                      <div className="col-lg-6 col-md-6 col-12">
                        <div class="card mb-6 p-4">
                          <h5 class="card-header px-0 text-white">
                            Withdrawal Transaction Limit(s)
                          </h5>
                          <form onSubmit={handleSettingPage}>
                            <h6 className=""> Affiliate Wallet </h6>
                            <div className="row g-3">
                              <div
                                key="affiliate_transaction_limit"
                                className="col-md-12"
                              >
                                <label className="form-label">
                                  No. of Transactions
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="affiliate_transaction_limit"
                                  value={
                                    formData["affiliate_transaction_limit"]
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div
                                key="affiliate_transaction_amount"
                                className="col-md-12"
                              >
                                <label className="form-label">
                                  No. of Amount
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="affiliate_transaction_amount"
                                  value={
                                    formData["affiliate_transaction_amount"]
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <h6 className="pt-4"> Staking Wallet </h6>
                            <div className="row g-3">
                              <div
                                key="staking_transaction_limit"
                                className="col-md-12"
                              >
                                <label className="form-label">
                                  No. of Transactions
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="staking_transaction_limit"
                                  value={formData["staking_transaction_limit"]}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div
                                key="staking_transaction_amount"
                                className="col-md-12"
                              >
                                <label className="form-label">
                                  No. of Amount
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="staking_transaction_amount"
                                  value={formData["staking_transaction_amount"]}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <h6 className="pt-4"> Royalty Wallet </h6>
                            <div className="row g-3 ">
                              <div
                                key="royalty_transaction_limit"
                                className="col-md-12"
                              >
                                <label className="form-label">
                                  No. of Transactions
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="royalty_transaction_limit"
                                  value={formData["royalty_transaction_limit"]}
                                  onChange={handleInputChange}
                                />
                              </div>

                              <div
                                key="royalty_transaction_amount"
                                className="col-md-12"
                              >
                                <label className="form-label">
                                  No. of Amount
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="royalty_transaction_amount"
                                  value={formData["royalty_transaction_amount"]}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <h6 className="pt-4"> Referral Wallet </h6>
                            <div className="row g-3 ">
                              <div
                                key="referral_transaction_limit"
                                className="col-md-12"
                              >
                                <label className="form-label">
                                  No. of Transactions
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="referral_transaction_limit"
                                  value={formData["referral_transaction_limit"]}
                                  onChange={handleInputChange}
                                />
                              </div>

                              <div
                                key="referral_transaction_amount"
                                className="col-md-12"
                              >
                                <label className="form-label">
                                  No. of Amount
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="referral_transaction_amount"
                                  value={
                                    formData["referral_transaction_amount"]
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <div className="pt-4">
                              <button
                                type="submit"
                                className="border-0 rounded loginBtn addUserBTn me-sm-3 me-1 waves-effect"
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-12">
                        <div class="card mb-6 p-4">
                          <h5 class="card-header px-0 text-white">
                            Fund Tranfer Limit(s)
                          </h5>
                          <form onSubmit={handleSettingPage}>
                            <h6 className=""> Affiliate Wallet </h6>
                            <div className="row g-3">
                              <div
                                key="affiliate_transaction_limit"
                                className="col-md-12"
                              >
                                <label className="form-label">
                                  No. of Transactions
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="ft_affiliate_transaction_limit"
                                  value={
                                    formData["ft_affiliate_transaction_limit"]
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div
                                key="affiliate_transaction_amount"
                                className="col-md-12"
                              >
                                <label className="form-label">
                                  No. of Amount
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="ft_affiliate_transaction_amount"
                                  value={
                                    formData["ft_affiliate_transaction_amount"]
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <h6 className="pt-4"> Staking Wallet </h6>
                            <div className="row g-3">
                              <div
                                key="staking_transaction_limit"
                                className="col-md-12"
                              >
                                <label className="form-label">
                                  No. of Transactions
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="ft_staking_transaction_limit"
                                  value={
                                    formData["ft_staking_transaction_limit"]
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div
                                key="staking_transaction_amount"
                                className="col-md-12"
                              >
                                <label className="form-label">
                                  No. of Amount
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="ft_staking_transaction_amount"
                                  value={
                                    formData["ft_staking_transaction_amount"]
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <h6 className="pt-4"> Royalty Wallet </h6>
                            <div className="row g-3 ">
                              <div
                                key="royalty_transaction_limit"
                                className="col-md-12"
                              >
                                <label className="form-label">
                                  No. of Transactions
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="ft_royalty_transaction_limit"
                                  value={
                                    formData["ft_royalty_transaction_limit"]
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>

                              <div
                                key="royalty_transaction_amount"
                                className="col-md-12"
                              >
                                <label className="form-label">
                                  No. of Amount
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="ft_royalty_transaction_amount"
                                  value={
                                    formData["ft_royalty_transaction_amount"]
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <h6 className="pt-4"> Referral Wallet </h6>
                            <div className="row g-3 ">
                              <div
                                key="ft_referral_transaction_limit"
                                className="col-md-12"
                              >
                                <label className="form-label">
                                  No. of Transactions
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="ft_referral_transaction_limit"
                                  value={
                                    formData["ft_referral_transaction_limit"]
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>

                              <div
                                key="ft_referral_transaction_amount"
                                className="col-md-12"
                              >
                                <label className="form-label">
                                  No. of Amount
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="ft_referral_transaction_amount"
                                  value={
                                    formData["ft_referral_transaction_amount"]
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <div className="pt-4">
                              <button
                                type="submit"
                                className="border-0 rounded loginBtn addUserBTn me-sm-3 me-1 waves-effect"
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Cron Tab Content*/}
                {activeTab === "cronTab" && (
                  <>
                    <div
                      className="tab-pane fade active show card-body"
                      role="tabpanel"
                    >
                      <button
                        className="btn btn-primary m-2"
                        onClick={() =>
                          handleCronAction(
                            "https://qbitdex.io:5000/api/v1/user/cron/give-daily-affiliate-to-user"
                          )
                        }
                        disabled={isProcessing}
                      >
                        Give Affiliate to Users
                      </button>
                      <button
                        className="btn btn-warning m-2"
                        onClick={() =>
                          handleCronAction(
                            "https://qbitdex.io:5000/api/v1/user/cron/stake-users-daily"
                          )
                        }
                        disabled={isProcessing}
                      >
                        Give Staking to Users
                      </button>

                      {/* Show processing message */}
                      {isProcessing && <p>Processing Cron...</p>}
                    </div>
                  </>
                )}

                {/* Cron Tab Content*/}
                {activeTab === "gauthenticator" && (
                  <>
                    <div
                      className="tab-pane fade active show card-body"
                      role="tabpanel"
                    >
                      <div>
                        <p>
                          Scan the QR code below with your Google Authenticator
                          app:
                        </p>
                        {qrCode ? (
                          <img
                            src={qrCode}
                            alt="Google Authenticator QR Code"
                          />
                        ) : (
                          <p>Loading QR code...</p>
                        )}
                      </div>
                    </div>

                    <div className="mt-3">
                      <p>Update Your Pattern</p>
                      <form onSubmit={handleSubmit}>
                        <div>
                          <ReactCanvasPatternLock
                            width={300} // Canvas width
                            height={300} // Canvas height
                            size={3} // Number of points per row/column (3x3 grid)
                            onComplete={handlePatternComplete}
                            style={{
                              backgroundColor: "transparent",
                              border: "2px solid #555",
                            }}
                          />
                        </div>
                        <button type="submit">Update Pattern</button>
                      </form>
                      {message && <p>{message}</p>}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsForm;
