import React from 'react'
import loaderImg from './loaderImg.png'

function Loader() {
    return (
        <>
            <div className='loader d-flex justify-content-center align-items-center' style={{ minHeight: '100vh', height: '100%' }}>
                <div className='loader__inner text-center'>
                    <img src={loaderImg} alt="loaderImg" width={'120px'} />
                    <div class="loading-text pt-5">
                        <span>L</span>
                        <span>O</span>
                        <span>A</span>
                        <span>D</span>
                        <span>I</span>
                        <span>N</span>
                        <span>G</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Loader
