import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { addUserRankCriteriaCondition, gerUserRank, singleUserRankCriteriaCondition, updateUserRankCriteriaCondition } from '../../common/Api';
import { useParams } from 'react-router-dom';


const RankCriteriaForm = () => {

    const params = useParams()

    const { id } = params;
    const { token } = useSelector((state) => state.user);

    const [getrank, setGetRank] = useState([])
    const [newbusiness, setNewbusiness] = useState(0)
    const [selectedrank, setSelectedRank] = useState('')

    console.log(id)

    const get_fund = async () => {
        try {
            const data = await gerUserRank(token);
            setGetRank(data.data);

        } catch (error) {
            toast.error('Failed to fetch Rank Limit.');
        }
    };

    useEffect(() => {
        get_fund()
    }, [])



    const handleSettingPage = async (e) => {

        e.preventDefault();

        const rankId = document.querySelector("#rank").value;
        if (rankId == '') {
            toast.error(`Please Select The Rank!`)
        }

        const submitData = {
            rank: rankId,
            newbusiness: newbusiness,
            matchid: rankId,
        }
        try {
            const data = await addUserRankCriteriaCondition(submitData, token)
            console.log(data)
            if (data.status == true) {
                toast.success(data.message)
                setInterval(() => {
                    window.location.reload();
                }, 2000)
            } else {
                toast.error(data.response.data.message)
            }

        } catch (error) {
            toast.error(error.response.data.message);
        }
    };


    const getSingleRank = async () => {
        try {
            const data = await singleUserRankCriteriaCondition(id, token);
            setNewbusiness(data.data.newbusiness)
            setSelectedRank(data.data.matchid)
        } catch (error) {
            toast.error('Failed to fetch Rank Limit.');
        }
    };


    useEffect(() => {
        if (id) {
            getSingleRank();
        } else {
            setNewbusiness('')
            setSelectedRank('')
        }

    }, [id])

    const handleUserRankUpdate = async () => {
        const rankId = document.querySelector("#rank").value;
        const submitData = {
            rank: rankId,
            newbusiness: newbusiness,
            matchid: rankId,
        }
        try {
            const data = await updateUserRankCriteriaCondition(id, submitData, token)
            console.log(data)
            if (data.status == true) {
                toast.success(data.message)
                setInterval(() => {
                    window.location.reload();
                }, 2000)
            } else {
                toast.error(data.response.data.message)
            }

        } catch (error) {
            toast.error(error.response.data.message);
        }

    }



    return (
        <div className="col-lg-4">
            <div className="card mb-3">
                <ToastContainer />
                <div className="card-header pt-2">
                    <ul className="nav nav-tabs card-header-tabs" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className='nav-link active'
                            >
                                Rank Maintain Criteria
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="tab-content">
                    <div className="tab-pane fade active show card-body" role="tabpanel">


                        <div className="row g-3">

                            <div className="col-md-12">
                                <label className="form-label">
                                    Rank
                                </label>
                                <select className='form-control' id="rank">
                                    <option>Select Rank</option>
                                    {
                                        getrank?.map((items, i) => {
                                            return (
                                                <>
                                                    {
                                                        selectedrank == items?._id ? <option value={items?._id} selected >{items?.name}</option> : <option value={items?._id}>{items?.name}</option>
                                                    }
                                                </>

                                            )
                                        })
                                    }

                                </select>

                            </div>

                            <div className="col-md-12">
                                <label className="form-label">
                                    No. of new business in last 30days
                                </label>
                                <input
                                    type='number'
                                    className="form-control"
                                    name='symbol'
                                    value={newbusiness}
                                    onChange={(e) => setNewbusiness(e.target.value)}

                                />
                            </div>
                        </div>

                        <div className="pt-4">
                            <button type="submit" className="btn btn-primary me-sm-3 me-1 waves-effect waves-light" onClick={id ? handleUserRankUpdate : handleSettingPage}>
                                Submit
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default RankCriteriaForm;
