import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { logout } from "../../Services/UserSlices";
import { btuser_single_data_get } from "../../common/Api";
import { assetUrl, Image } from "./Url";
import SweetAlert2 from "react-sweetalert2";
import btclogo from "./img/btclogo.png";
import sunI from "./img/sun.png";
import dashboardI from './img/dashboard.svg'
import fundI from "./img/fund.svg";
import networkI from "./img/network.svg";
import logoutI from "./img/logout.svg";
import userI from "./img/user.svg";
import screenshotI from "./img/screenshot.svg";
import depositI from "./img/deposit.svg";
import withdrawlsI from "./img/withdrawl.svg";
import planI from "./img/plan.svg";
import incomeSummaryI from "./img/incomeSummary.svg";
import settingI from "./img/setting.svg";
import rightI from "./img/right.svg";
import rankImg from "./img/rankImg.png";


const HeaderComponent = () => {
  const { user } = useSelector((state) => state.user);
  const admin = JSON.parse(localStorage.getItem("admin"))

  const { token, user: currentUser, setting } = useSelector(
    (state) => state.user
  );
  const [users, setUsers] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [income, setIncome] = useState(false);
  const sidebarRef = useRef(null);
  const [swalProps, setSwalProps] = useState({});

  const handleLogout = () => {
    setSwalProps({
      show: true,
      title: "Are you sure?",
      text: "You will be logged out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, log me out!",
      cancelButtonText: "Cancel",
    });
  };

  const confirmLogout = () => {
    dispatch(logout());
    navigate("/");
  };
  // console.log(setting);

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen2(false);
    }
  };

  const btGetSingleUserData = async () => {
    try {
      const data = await btuser_single_data_get(user._id, token);
      setUsers(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    btGetSingleUserData();
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggle2 = () => {
    setIsOpen2(!isOpen2); // Toggle the sidebar open/close
  };

  const handleIncomeToggle = (e) => {
    e.preventDefault();
    setIncome(!income);
  };

  const handleToggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const closeSidebarOnMobile = () => {
    setIsOpen2(false);
  };

  const changeMode = (event) => {
    const isChecked = event.target.checked;
    const cssLinks = document.querySelectorAll('.themeStyles'); // Select all relevant <link> tags

    const lightThemes = [
      '/assets/vendor/css/rtl/core.css',
      '/assets/vendor/css/rtl/theme-default.css',
    ];

    const darkThemes = [
      '/assets/vendor/css/rtl/core-dark.css',
      '/assets/vendor/css/rtl/theme-default-dark.css',
    ];

    const themesToApply = isChecked ? lightThemes : darkThemes;

    cssLinks.forEach((link, index) => {
      link.href = themesToApply[index]; // Update each link href
    });
  };

  return (
    <>
      <aside
        ref={sidebarRef}
        id="layout-menu"
        className={`layout-menu menu-vertical menu bg-menu-theme d-block d-lg-none d-md-none  ${isOpen2 ? "open" : ""
          }`}
        style={{
          borderRight: "1px solid #e6ecf52c",
          width: isOpen2 ? "250px" : "100px", // Adjust width based on state
          textAlign: "center",
          transition: "width 0.3s",
        }}
      >
        <div className="app-brand demo mt-4" style={{ margin: "auto" }}>
          <Link to="/admin/dashboard" className="app-brand-link">
            <span className="app-brand-logo demo">
              <img
                className="mt-2 mb-2"
                src={btclogo}
                width="40px"
                alt="BTC Logo"
              />
            </span>
          </Link>
        </div>
        <p>
          <b>BTCASH</b>
        </p>

        <button
          className="layout-menu-toggle menu-link text-large ms-auto w-100"
          onClick={handleToggle2}
          style={{ background: "none", border: "none" }}
        >
          {isOpen ? (
            <i className="bg-transparent d-flex justify-content-center align-items-center">
              <img src={rightI} alt="" className="" style={{ width: '22px', rotate: '180deg' }} />
            </i>
          ) : (
            <i className="bg-transparent d-flex justify-content-center align-items-center">
              <img src={rightI} alt="" className="" style={{ width: '22px' }} />
            </i>
          )}
        </button>
        <div className="menu-inner-shadow"></div>

        <ul className="menu-inner py-1">
          <li className="menu-item open">
            <Link to="/admin/dashboard" className="menu-link sidemenu-dash">
              {/* <i class="menu-icon tf-icons ti ti-smart-home"></i> */}
              <i className="bg-transparent d-flex justify-content-center align-items-center">
                <img src={dashboardI} alt="" className="" style={{ width: '22px' }} />
              </i>
              <div data-i18n="Analytics">Dashboard</div>
            </Link>
          </li>

          {(user?.isAdmin === "admin" ||
            (user?.isAdmin === "sub-admin" &&
              ["admin/add-user", "admin/user-list"].some((permission) =>
                user.permission.includes(permission)
              ))) && (
              <>
                {/* <li className="menu-header small text-uppercase">
            <span className="menu-header-text" data-i18n="Pages">
              {" "}
              Users
            </span>
          </li> */}
                <li className={`menu-item  ${isOpen ? "open" : ""}`}>
                  <Link
                    to="#"
                    className="menu-link menu-toggle sidemenu-dash"
                    onClick={handleToggle}
                  >
                    {/* <i className="menu-icon tf-icons ti ti-users"></i> */}
                    <i className="bg-transparent d-flex justify-content-center align-items-center">
                      <img src={userI} alt="" className="" style={{ width: '22px' }} />
                    </i>
                    <div data-i18n="Specialities">Users</div>
                  </Link>
                  <ul className="menu-sub">
                    {(user?.isAdmin === "admin" ||
                      (user?.isAdmin === "sub-admin" &&
                        user.permission.includes("admin/add-user"))) && (
                        <li className="menu-item">
                          <Link
                            to="/admin/add-user"
                            className="menu-link sidemenu-dash"
                          >
                            <div data-i18n="Add Speciality">Add User</div>
                          </Link>
                        </li>
                      )}
                    {(user?.isAdmin === "admin" ||
                      (user?.isAdmin === "sub-admin" &&
                        user.permission.includes("admin/user-list"))) && (
                        <li className="menu-item">
                          <Link
                            to="/admin/user-list"
                            className="menu-link sidemenu-dash"
                          >
                            <div data-i18n="Specialities List">Users List</div>
                          </Link>
                        </li>
                      )}
                    {user?.isAdmin === "admin" && (
                      <li className="menu-item">
                        <Link
                          to="/admin/roles-permission"
                          className="menu-link sidemenu-dash"
                        >
                          <div data-i18n="Specialities List">Page & Url</div>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </>
            )}

          {/* <li className="menu-header small text-uppercase">
      <span className="menu-header-text" data-i18n="Pages">
        {" "}
        Attachments
      </span>
    </li> */}
          <li className="menu-item">
            <Link
              to="/admin/uploaded-screenshots"
              className="menu-link sidemenu-dash"
            >
              {/* <i class="menu-icon ti ti-screenshot"></i> */}
              <i className="bg-transparent d-flex justify-content-center align-items-center">
                <img src={screenshotI} alt="" className="" style={{ width: '24px' }} />
              </i>
              <div data-i18n="Uploaded Screenshots">Uploaded Screenshots</div>
            </Link>
          </li>

          <li className="menu-item">
            <Link to="/admin/deposit" className="menu-link sidemenu-dash">
              {/* <i className="menu-icon tf-icons ti ti-components"></i> */}
              <i className="bg-transparent d-flex justify-content-center align-items-center">
                <img src={depositI} alt="" className="" style={{ width: '25px' }} />
              </i>
              <div data-i18n="Deposit">Deposit</div>
            </Link>
          </li>

          <li className="menu-item">
            <Link to="/admin/withdrawls" className="menu-link sidemenu-dash">
              {/* <i class="menu-icon fa-solid fa-money-bill-transfer"></i> */}
              <i className="bg-transparent d-flex justify-content-center align-items-center">
                <img src={withdrawlsI} alt="" className="" style={{ width: '25px' }} />
              </i>
              <div data-i18n="Withdrawls">Withdrawls</div>
            </Link>
          </li>

          {(user?.isAdmin === "admin" ||
            (user?.isAdmin === "sub-admin" &&
              ["admin/add-user", "admin/plan"].some((permission) =>
                user.permission.includes(permission)
              ))) && (
              <>
                {/* <li className="menu-header small text-uppercase">
            <span className="menu-header-text" data-i18n="Pages">
              {" "}
              Income
            </span>
          </li> */}
                {(user?.isAdmin === "admin" ||
                  (user?.isAdmin === "sub-admin" &&
                    user.permission.includes("admin/plan"))) && (
                    <li className="menu-item">
                      <Link to="/admin/plan" className="menu-link sidemenu-dash">
                        {/* <i class="fa-solid fa-people-group"></i> */}
                        <i className="bg-transparent d-flex justify-content-center align-items-center">
                          <img src={planI} alt="" className="" style={{ width: '25px' }} />
                        </i>
                        <div data-i18n="Plan">Plan</div>
                      </Link>
                    </li>
                  )}
                <li className={`menu-item  ${income ? "open" : ""}`}>
                  <Link
                    to="#"
                    className="menu-link menu-toggle sidemenu-dash"
                    onClick={handleIncomeToggle}
                  >
                    {/* <i className="menu-icon tf-icons ti ti-file-dollar"></i> */}
                    <i className="bg-transparent d-flex justify-content-center align-items-center">
                      <img src={incomeSummaryI} alt="" className="" style={{ width: '25px' }} />
                    </i>
                    <div data-i18n="Income Summary">Income Summary</div>
                  </Link>
                  <ul className="menu-sub">
                    {(user?.isAdmin === "admin" ||
                      (user?.isAdmin === "sub-admin" &&
                        user.permission.includes("admin/staking-reward"))) && (
                        <li className="menu-item">
                          <Link
                            to="/admin/staking-reward"
                            className="menu-link sidemenu-dash"
                          >
                            <div data-i18n="Staking Rewards">Staking Rewards</div>
                          </Link>
                        </li>
                      )}
                    {(user?.isAdmin === "admin" ||
                      (user?.isAdmin === "sub-admin" &&
                        user.permission.includes("admin/affiliate"))) && (
                        <li className="menu-item">
                          <Link
                            to="/admin/affiliate"
                            className="menu-link sidemenu-dash"
                          >
                            <div data-i18n="Affiliate Rewards">Affiliate Rewards</div>
                          </Link>
                        </li>
                      )}
                    {(user?.isAdmin === "admin" ||
                      (user?.isAdmin === "sub-admin" &&
                        user.permission.includes("admin/royalty-rewards"))) && (
                        <li className="menu-item">
                          <Link
                            to="/admin/royalty-rewards"
                            className="menu-link sidemenu-dash"
                          >
                            <div data-i18n="Affiliate Rewards">Royalty Rewards</div>
                          </Link>
                        </li>
                      )}
                  </ul>
                </li>
              </>
            )}

          {(user?.isAdmin === "admin" ||
            (user?.isAdmin === "sub-admin" &&
              ["fund-transfer"].some((permission) =>
                user.permission.includes(permission)
              ))) && (
              <>
                {/* <li className="menu-header small text-uppercase">
            <span className="menu-header-text" data-i18n="Pages">
              {" "}
              Fund Transfer
            </span>
          </li> */}
                {(user?.isAdmin === "admin" ||
                  (user?.isAdmin === "sub-admin" &&
                    user.permission.includes("admin/fund-transfer"))) && (
                    <li className="menu-item">
                      <Link
                        to="/admin/fund-transfer"
                        className="menu-link sidemenu-dash"
                      >
                        {/* <i className="menu-icon tf-icons ti ti-layout-kanban"></i> */}
                        <i className="bg-transparent d-flex justify-content-center align-items-center">
                          <img src={fundI} alt="" className="" style={{ width: '25px' }} />
                        </i>
                        <div data-i18n="Chat">Fund Transfer</div>
                      </Link>
                    </li>
                  )}
              </>
            )}

          <li className="menu-item open">
            <Link to="/admin/user-rank" className="menu-link sidemenu-dash">
              {/* <i class="menu-icon tf-icons ti ti-layout-kanban"></i> */}
              <i className="bg-transparent d-flex justify-content-center align-items-center">
                <img src={rankImg} alt="" className="" style={{ width: '24px' }} />
              </i>
              <div data-i18n="Network">Rank</div>
            </Link>
          </li>


          {/* {
      (user?.isAdmin === 'admin' ||
        (user?.isAdmin === 'sub-admin' &&
          ['admin/blog', 'admin/blog/add', 'admin/blog/category/add', 'admin/blog/tag/add'].some(permission => user.permission.includes(permission)))
      ) && (
        <>
          <li className="menu-header small text-uppercase">
            <span className="menu-header-text" data-i18n="SETTINGS"> Blogs</span>
          </li>
          <li className={`menu-item  ${category ? 'open' : ''}`}>
            <a href="#" className="menu-link menu-toggle" onClick={handleCategory}>
              <i className="menu-icon tf-icons ti ti-square"></i>
              <div data-i18n="Specialities">Posts</div>
            </a>
            <ul className="menu-sub">
              {
                (user?.isAdmin === 'admin' || (user?.isAdmin === 'sub-admin' && user.permission.includes('admin/blog'))) && (
                  <li className="menu-item">
                    <Link to="/admin/blog" className="menu-link sidemenu-dash">
                      <div data-i18n="Add Slider">All Posts</div>
                    </Link>
                  </li>
                )}

              {
                (user?.isAdmin === 'admin' || (user?.isAdmin === 'sub-admin' && user.permission.includes('admin/blog/add'))) && (
                  <li className="menu-item">
                    <Link to="/admin/blog/add" className="menu-link sidemenu-dash">
                      <div data-i18n="Add Slider">Add New</div>
                    </Link>
                  </li>
                )}

              {
                (user?.isAdmin === 'admin' || (user?.isAdmin === 'sub-admin' && user.permission.includes('admin/blog/category/add'))) && (
                  <li className="menu-item">
                    <Link to="/admin/blog/category/add" className="menu-link sidemenu-dash">
                      <div data-i18n="Add Slider">Category</div>
                    </Link>
                  </li>
                )}

              {
                (user?.isAdmin === 'admin' || (user?.isAdmin === 'sub-admin' && user.permission.includes('admin/blog/tag/add'))) && (

                  <li className="menu-item">
                    <Link to="/admin/blog/tag/add" className="menu-link sidemenu-dash">
                      <div data-i18n="Add Slider">Tag</div>
                    </Link>
                  </li>
                )}

            </ul>
          </li>

        </>
      )} */}

          <li className="menu-item open">
            <Link to="/admin/network" className="menu-link sidemenu-dash">
              {/* <i class="menu-icon tf-icons ti ti-layout-kanban"></i> */}
              <i className="bg-transparent d-flex justify-content-center align-items-center">
                <img src={networkI} alt="" className="" style={{ width: '24px' }} />
              </i>
              <div data-i18n="Network">Network</div>
            </Link>
          </li>

          {(user?.isAdmin === "admin" ||
            (user?.isAdmin === "sub-admin" &&
              ["admin/settings"].some((permission) =>
                user.permission.includes(permission)
              ))) && (
              <>
                {/* <li className="menu-header small text-uppercase">
            <span className="menu-header-text" data-i18n="SETTINGS">
              {" "}
              SETTINGS
            </span>
          </li> */}
                <li className="menu-item">
                  <Link to="/admin/settings" className="menu-link sidemenu-dash">
                    {/* <i className="menu-icon tf-icons ti ti-settings"></i> */}
                    <i className="bg-transparent d-flex justify-content-center align-items-center">
                      <img src={settingI} alt="" className="" style={{ width: '22px' }} />
                    </i>
                    <div data-i18n="Chat">Settings</div>
                  </Link>
                </li>
              </>
            )}



          <li className="menu-item">
            <Link className="menu-link sidemenu-dash" onClick={handleLogout}>
              {/* <i className="menu-icon tf-icons ti ti ti-logout"></i> */}
              <i className="bg-transparent d-flex justify-content-center align-items-center">
                <img src={logoutI} alt="" className="" style={{ width: '22px' }} />
              </i>
              <div data-i18n="Chat"> Log Out</div>
            </Link>
          </li>

          {/* <li className="pt-5">
          <div class="d-flex justify-content-center align-items-center gap-2 px-0 w-100 ps-2">
            <div className="w-100">
              <img src={sunI} alt="" style={{ width: '20px' }} />
            </div>
            <label class="switch switch-primary me-0">
              <input type="checkbox" class="switch-input" id="modeChanges" onChange={changeMode} />
              <span class="switch-toggle-slider">
                <span class="switch-on"></span>
                <span class="switch-off"></span>
              </span>
            </label>
          </div>
        </li> */}

        </ul>
      </aside>

      <nav
        className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div
          className="navbar-nav-right d-flex align-items-center"
          id="navbar-collapse"
        >
          <div className="d-block d-lg-none d-md-none">
            <Link to="/admin/dashboard" className="app-brand-link">
              <span className="app-brand-logo demo">
                <img
                  className="mt-2 mb-2"
                  src={btclogo}
                  width="40px"
                  alt="BTC Logo"
                />
              </span>
            </Link>
          </div>

          <div className="d-block d-lg-none d-md-none">
            <button
              className="layout-menu-toggle menu-link text-large ms-auto w-100"
              onClick={handleToggle2}
              style={{ background: "none", border: "none" }}
            >
              {isOpen2 ? (
                <i
                  class="fa-solid fa-bars"
                  style={{ color: "#C4C4C4", fontSize: "1.3rem" }}
                ></i> // Close icon
              ) : (
                <i
                  class="fa-solid fa-bars"
                  style={{ color: "#C4C4C4", fontSize: "1.3rem" }}
                ></i> // Menu icon
              )}
            </button>
          </div>

          <div className="navbar-nav align-items-center">
            <div className="nav-item navbar-search-wrapper mb-0">
              <Link
                className="nav-item nav-link search-toggler d-flex align-items-center px-0"
                to="javascript:void(0);"
              >
                <div className="top-title-layout d-none d-md-inline-block text-muted fw-normal">
                  
                  <span className="userEmail">
                    {" "}
                    {admin == true ? '' : <>
                      <span className="user-welcome">Welcome Back ,</span>  {user?.email} ({user?.btucode})
                    </>}
                  </span>
                </div>
              </Link>
            </div>
          </div>

          <ul className="navbar-nav flex-row align-items-center ms-auto">
            <li className="nav-item navbar-dropdown dropdown-user dropdown pt-1 pt-lg-0 pt-md-0" style={{ marginInline: "35px" }}>
              <div class="d-flex justify-content-center align-items-center gap-2">
                <div className="w-100">
                  <img src={sunI} alt="" style={{ width: '20px' }} />
                </div>
                <label class="switch switch-primary ">
                  <input type="checkbox" class="switch-input" id="modeChanges" onChange={changeMode} />
                  <span class="switch-toggle-slider">
                    <span class="switch-on"></span>
                    <span class="switch-off"></span>
                  </span>
                </label>
              </div>
            </li>

            <li className="nav-item navbar-dropdown dropdown-user dropdown">
              <a
                className="nav-link dropdown-toggle hide-arrow"
                href="#"
                data-bs-toggle="dropdown"
              >
                <div className="avatar avatar-online">
                  <img
                    src={
                      users?.profileImg
                        ? `${Image}/uploads/${users?.profileImg}`
                        : `${Image}/uploads/default-avatar.jpg`
                    }
                    className="h-auto rounded-circle"
                    alt="User Avatar"
                  />
                </div>
              </a>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <Link to={"/admin/profile"} className="dropdown-item">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar avatar-online">
                          <img
                            src={
                              users?.profileImg
                                ? `${Image}/uploads/${users?.profileImg}`
                                : `${Image}/uploads/default-avatar.jpg`
                            }
                            className="h-auto rounded-circle"
                            alt="User Avatar"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <span className="fw-medium d-block">
                          {currentUser?.name}
                        </span>
                        <small className="text-muted">Admin</small>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                {/* <li>
                  <Link to={"/admin/profile"} className="dropdown-item">
                    <i className="ti ti-user-check me-2 ti-sm"></i>
                    <span className="align-middle">My Profile</span>
                  </Link>
                </li> */}
                <li>
                  <Link to={"/admin/settings"} className="dropdown-item">
                    <i className="ti ti-settings me-2 ti-sm"></i>
                    <span className="align-middle">Settings</span>
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <Link
                    className="btn btn-sm btn-danger d-flex waves-effect waves-light"
                    onClick={handleLogout}
                  >
                    <small className="align-middle">Logout</small>
                    <i className="ti ti-logout ms-2 ti-14px"></i>
                  </Link>

                  <SweetAlert2
                    {...swalProps}
                    didOpen={() => {
                      // run when swal is opened...
                    }}
                    didClose={() => {
                      // run when swal is closed...
                    }}
                    onConfirm={(result) => {
                      confirmLogout();
                    }}
                    onError={(error) => {
                      // run when promise rejected...
                    }}
                    onResolve={(result) => {
                      // run when promise is resolved...
                    }}
                  />
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default HeaderComponent;
