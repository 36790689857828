import React, { useState, useEffect } from 'react';
import { getDirectUserReferal } from '../../../common/Api';
import { useSelector } from 'react-redux';
import './NewTree.css'

const UserList = () => {
    const { user, token } = useSelector((state) => state.user);
    const [users, setUsers] = useState([]);
    const [expandedUsers, setExpandedUsers] = useState({});
    const [hoveredUser, setHoveredUser] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await getDirectUserReferal(user.btucode, token);
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, [user.btucode, token]);

    const handleToggle = async (email, ucode) => {
        setExpandedUsers((prev) => {
            const isExpanded = !!prev[email];
            if (isExpanded) {
                // Collapse the user
                const { [email]: _, ...rest } = prev;
                return rest;
            } else {
                // Fetch sub-users if expanding
                const fetchSubUsers = async () => {
                    try {
                        const response = await getDirectUserReferal(ucode, token);
                        return response.data;
                    } catch (error) {
                        console.error('Error fetching sub-users:', error);
                        return [];
                    }
                };

                // Set expanded user with sub-users
                fetchSubUsers().then((subUsers) => {
                    setExpandedUsers((current) => ({
                        ...current,
                        [email]: subUsers,
                    }));
                });

                return { ...prev, [email]: [] }; // Temporarily set empty array while fetching
            }
        });
    };

    const getRankName = (rankId) => {
        const rankCritica = [
            { id: 1, name: "Basic Club" },
            { id: 2, name: "Meta Founder Club" },
            { id: 3, name: "Meta Star Club" },
            { id: 4, name: "Meta Royal Club" },
            { id: 5, name: "Meta Prime Club" },
            { id: 6, name: "Meta Ambassador Club" },
        ];
        const rank = rankCritica.find((r) => r.id === rankId);
        return rank ? rank.name : "-NA-"; // Return rank name or '-NA-' if not found
    };


    const renderUsers = (userList) => {
        return (
            <ul class="tree">
                {userList.map((user, i) => (
                    <li
                        key={i}
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent click from bubbling up
                            handleToggle(user.email, user.btucode);
                        }}
                        onMouseEnter={() => setHoveredUser(user)}
                        onMouseLeave={() => setHoveredUser(null)}
                    >
                        <i className={`ti ti-chevron-${expandedUsers[user.email] ? 'up' : 'down'}`}></i>
                        {user.username ? user.username : user.btucode}
                        {expandedUsers[user.email] && renderUsers(expandedUsers[user.email])}

                        {/* Show user details on hover */}
                        {hoveredUser === user && (
                            <div  className="cardInfo_Tree" style={{
                                position: 'absolute',
                                left: '15rem',
                                top: '0',
                                background: 'linear-gradient(24deg, #211328 7%, #1B162E)',
                                border: '1px solid #5a5a5a',
                                padding: '1rem',
                                borderRadius: '0.5rem',
                                zIndex: 100,
                                
                            }}>
                                
                                <p style={{color: '#d3d3d3'}} className='mb-1'><strong>Email:</strong> {user.email}</p>
                                <p style={{color: '#d3d3d3'}} className='mb-1'><strong>Code:</strong> {user.btucode}</p>
                                <p style={{color: '#d3d3d3'}} className='mb-1'><strong>Level : </strong> {user.btLevel}</p>
                                <p style={{color: '#d3d3d3'}} className='mb-1'><strong>Rank :</strong> {getRankName(user.btRank)}</p>
                                <p style={{color: '#d3d3d3'}} className='mb-1'><strong>Joining Date : </strong> {new Date(user.createdAt).toLocaleDateString('en-GB', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric'
                                })}</p>
                                {/* Add more user details as needed */}
                            </div>
                        )}
                    </li>
                ))}
                </ul>
        );
    };

    return (
        <div >
            {users.length > 0 ? renderUsers(users) : <div>No users found</div>}
        </div>
    );
};

// Example usage in the main App component
const NewTree = () => {
    return <UserList />;
};

export default NewTree;
