import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { url, Image } from "../../Components/Common/Url";
import { useDispatch, useSelector } from "react-redux";
import { getAdmin } from "../../Services/UserSlices";
import { ToastContainer, toast } from "react-toastify";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import btlogo from "../../btclogo.png";
import Swal from "sweetalert2";
import { ReactCanvasPatternLock } from "react-canvas-pattern-lock";

export default function AdminLoginPage() {
  const navigation = useNavigate();
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { token, user } = useSelector((state) => state.user);
  const [showPassword, setShowPassword] = useState(false);
  const [msg, setMsg] = useState(false);
  // CAPTCHA state
  const [captcha, setCaptcha] = useState("");
  const [captchaAnswer, setCaptchaAnswer] = useState("");

  const [showPatternLock, setShowPatternLock] = useState(true);
  const [showLoinForm, setShowLoinForm] = useState(false);
  const [patternCode, setPatternCode] = useState("");

  // Extract the code from the URL
  const { gcode } = useParams(); // Extract 'gcode' from URL

  const code = gcode;

  useEffect(() => {
    if (!code) {
      setError("No authentication code provided.");
      navigation("/"); // Redirect only once
    } else {
      verifyCode();
      generateCaptcha();
    }
  }, [code, navigation]); // Run this effect only when `code` changes

  if (!code) {
    return null; // Prevent rendering further if no code
  }

  // Verify the code with the backend
  const verifyCode = async () => {
    try {
      const response = await axios.post(`${url}/api/verify-gotp`, {
        token: code,
      });

      if (response.data.success) {
        setShowLoinForm(true);
      } else {
        setShowLoinForm(false);
        return Swal.fire({
          icon: "error",
          title: "",
          text: "Invalid code provided",
        });
      }
    } catch (err) {
      setShowLoinForm(false);
      return Swal.fire({
        icon: "error",
        title: "",
        text: "Something gone wrong. Please try again later.",
      });
    }
  };

  const handlePatternComplete = async (pattern) => {
    const patternAdded = pattern.join("");
    setPatternCode(patternAdded);

    console.log("pattern ==> ", patternAdded);
  };

  const verifyPattern = async (code) => {
    try {
      const response = await axios.post(`${url}/api/verify-pattern`, {
        token: code,
      });

      if (response.data.success) {
        setShowPatternLock(false);
        setShowLoinForm(true);
      } else {
        return Swal.fire({
          icon: "error",
          title: "",
          text: "Incorrect pattern. Try again.",
        });
      }
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "",
        text: "Something gone wrong. Please try again later.",
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptcha(`${num1} + ${num2}`);
    setCaptchaAnswer(num1 + num2);
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Please enter user email")
      .email("Invalid email format"),
    password: Yup.string().required("Please enter password"),
  });

  const handleLogin = async (formValue) => {
    const { email, password } = formValue;

    console.log("pattern code", patternCode);
    if (patternCode.length === 0) {
      return Swal.fire({
        icon: "error",
        title: "",
        text: "Please complete the pattern before submitting.",
      });
    }

    const data = { email, passwords: password, patternCode };

    setLoading(true);
    toast.dismiss();
    try {
      const resultAction = await dispatch(getAdmin(data));
      console.log(resultAction);
      if (getAdmin.fulfilled.match(resultAction)) {
        console.log(resultAction);
        toast.success("Login Successful");
        setInterval(() => {
          localStorage.setItem("refresh", "refresh");
          navigation("/admin/dashboard");
        }, 2000);
      } else {
        return Swal.fire({
          icon: "error",
          title: "",
          text: resultAction.payload || "Login failed",
        });
        //setMsg(resultAction.payload || "Login failed");
        //toast.error(resultAction.payload || 'Login failed');
      }
    } catch (error) {
      toast.error("Failed to fetch the user");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" />
      <section class="login_form_sec">
        <div className="container-xxl">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner py-4">
              <div className="card bg-transparent">
                <div class="d-flex gap-2 logo justify-content-center pb-3">
                  <img src={btlogo} width="200px" class="logo pt-1" />
                </div>
                <div className="card-body staking-reward2 border-0 rounded-0">
                  {showLoinForm ? (
                    <>
                      <p className="fw-bold text-white">Login Your Account</p>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleLogin}
                      >
                        <Form className="mb-3 login-form">
                          <div className="mb-3">
                            <label className="form-label">Email ID *</label>
                            <Field
                              name="email"
                              type="text"
                              className="form-control"
                              placeholder="Enter your email"
                            />
                            <ErrorMessage
                              name="email"
                              component="span"
                              className="errorInput"
                            />
                          </div>
                          <div className="mb-3 form-password-toggle">
                            <div className="d-flex justify-content-between">
                              <label className="form-label">Password *</label>
                            </div>
                            <div className="input-group input-group-merge">
                              <Field
                                name="password"
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                              />
                              <span
                                className="input-group-text cursor-pointer"
                                onClick={togglePasswordVisibility}
                              >
                                {showPassword ? (
                                  <i className="ti ti-eye"></i>
                                ) : (
                                  <i className="ti ti-eye-off"></i>
                                )}
                              </span>
                            </div>
                            <ErrorMessage
                              name="password"
                              component="span"
                              className="errorInput"
                            />
                          </div>

                          <div className="mb-3">
                            <label className="form-label">Pattern Code</label>
                            <ReactCanvasPatternLock
                              width={300} // Canvas width
                              height={300} // Canvas height
                              size={3} // Number of points per row/column (e.g., 3x3 grid)
                              onComplete={handlePatternComplete}
                              style={{
                                backgroundColor: "transparent", // Transparent canvas background
                                border: "2px solid #555", // Optional border styling
                              }}
                            />
                          </div>

                          <div className="mb-2 pt-1 text-center ">
                            <button
                              type="submit"
                              className="border-0 rounded d-grid w-100 loginBtn d-flex gap-1 align-items-center justify-content-center"
                              disabled={loading}
                            >
                              {loading && (
                                <span className="spinner-border spinner-border-sm fw-bold text-center"></span>
                              )}
                              <span>Login</span>
                            </button>
                          </div>
                          {msg && (
                            <p style={{ color: "red", textAlign: "center" }}>
                              {msg}
                            </p>
                          )}
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="form-check mb-0 d-flex align-items-center">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="remember-me"
                              />
                              <label className="form-check-label mb-0 ms-2 links-login">
                                {" "}
                                Remember Me{" "}
                              </label>
                            </div>
                            <div className="form-check"></div>
                          </div>
                        </Form>
                      </Formik>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
