// LineChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';

ChartJS.register(...registerables);
ChartJS.defaults.font.size = 12;

const TwoLineChart = ({ datas1, datas2 }) => {
    // Check if datas are provided
    // if ((!datas1 || datas1.length === 0) && (!datas2 || datas2.length === 0)) {
    //     return <div>No data available</div>;
    // }

    // Prepare your data
    const labels = datas1.map(data => {
        const date = new Date(data.creditDate);
        return `${date.getDate()}/${date.getMonth() + 1}`; // Format as "DD/MM"
    });

    const amounts1 = datas1.map(data => data.totalstaking); // Data for the first dataset
    const amounts2 = datas2.map(data => data.actualaffiliate); // Data for the second dataset

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Staking',
                data: amounts1,
                backgroundColor: 'rgba(153, 102, 255, 0.4)', // Area fill color for dataset 2
                borderColor: 'rgba(153, 102, 255, 1)', // Line color for dataset 2


                borderWidth: 2,
                // fill: true, // Fill the area under the line
                tension: 0.4, // Smoothing the line
            },
            {
                label: 'Affiliate',
                data: amounts2,
                backgroundColor: 'rgba(75, 192, 192, 0.4)', // Area fill color for dataset 1
                borderColor: 'rgba(75, 192, 192, 1)', // Line color for dataset 1
                borderWidth: 2,
                fill: true, // Fill the area under the line
                tension: 0.4, // Smoothing the line
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Date (DD/MM)',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Amounts',
                },
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
    };

    return (
        <div className='kuch'>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default TwoLineChart;
