import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { get_reward_update, get_stake_plan_update } from "../../../common/Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import StakingHistory from "./StakingHistory";

export default function StakingTab() {
  const { user, token } = useSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState("home");
  const [data, setData] = useState([]);

  const get_user_record = async (type) => {
    try {
      const response = await get_stake_plan_update(user._id, token, type);
     // console.log(response);
      setData(response.data);
    } catch (error) {
      toast.error("Failed to fetch data");
    }
  };
  const handleSelect = (key) => {
    setActiveTab(key);
    switch (key) {
      case "home":
        get_user_record(1);
        break;
      case "staking":
        get_user_record(2);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (activeTab === "home") {
      get_user_record(1);
    }
  }, [activeTab, user._id, token]);

  return (
    <div className="container-xxl flex-grow-1 container-p-y ">
      <Tabs
        activeKey={activeTab}
        onSelect={handleSelect}
        id="noanim-tab-example"
        className="mb-3 tabs"
      >
        <Tab eventKey="home" title="Active Staking">
          <StakingHistory sample={data} />
        </Tab>
        <Tab eventKey="staking" title="Closing Staking">
          <StakingHistory sample={data} />
        </Tab>
      </Tabs>
    </div>
  );
}
