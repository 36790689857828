import React from 'react'
import RankConditionForm from './RankConditionForm'
import RankConditionList from './RankConditionList'


export default function RankConditionPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <RankConditionForm />
                <RankConditionList />
            </div>
        </div>
    )
}
