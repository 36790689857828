import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify'; // Optional for notifications
import { useSelector } from 'react-redux';
import {deleteUserRankCriteriaCondition, gerUserRankCriteriaCondition} from '../../common/Api';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';

const RankCriteriaList = () => {

    const navigate = useNavigate()
    const { token } = useSelector((state) => state.user);
    const [fund, setFund] = useState([]);
    const [filteredCategory, setFilteredCategory] = useState([]);
    const [search, setSearch] = useState('');
    const [show, setShow] = useState(false);
    const [selectedFund, setSelectedFund] = useState({});

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const get_fund = async () => {
        try {
            const data = await gerUserRankCriteriaCondition(token);
            console.log(data)
            setFund(data.data);
            setFilteredCategory(data.data);
        } catch (error) {
            toast.error('Failed to fetch Rank Limit.');
        }
    };

    useEffect(() => {
        get_fund();
    }, [token]);

    useEffect(() => {
        const result = (fund || []).filter(user =>
            user?.rank?.name?.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredCategory(result);
    }, [search, fund]);

    const handleFundView = (fundData) => {
        setSelectedFund(fundData);
        setShow(true);
    };


    const handledelete = async (id) => {
        const confirm = window.confirm('Are you sure to delete this Rank?');
        if (confirm) {
            try {
                const data = await deleteUserRankCriteriaCondition(id, token)
                if (data.status == true) {
                    toast.success(data.message)
                    get_fund();
                }
            } catch (error) {
                toast.error(error.message);
            }
        }
    };

    const handleEdit = async (id) => {
        navigate(`/admin/user-rank/${id}`);
    };



    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Rank',
            selector: row => row?.rank?.name,
            sortable: true,
        },
        {
            name: 'Monthly Business',
            selector: row => row?.newbusiness,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                    <Link to={`/admin/rank-maintain-criteria/${row._id}`}>  <i className="fas fa-edit me-2" style={{ cursor: "pointer", color: "green" }}></i></Link>
                    <i className="fas fa-trash me-2" style={{ cursor: "pointer", color: "red" }} onClick={() => handledelete(row._id)}></i>
                </>
            ),
        },
    ];

    return (
        <div className='col-lg-8'>
            <div className="card mb-3">
                <h5 className="card-header">Rank Maintain Criteria List</h5>
                <div className="card-body">
                    <div className="mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Name"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredCategory}
                        pagination
                        highlightOnHover
                        striped
                    />
                </div>
            </div>
        </div>
    );
};

// Utility function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export default RankCriteriaList;
