import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import { upload_payment_screenshot } from "../../../common/Api";

function AttachedPaymentScreenshot() {
  const [selectedFile, setSelectedFile] = useState(null);
  const { user, token } = useSelector((state) => state.user);
  const [preview, setPreview] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.type == "image/png" || file.type == "image/jpg") {
      if (file) {
        setSelectedFile(file);
        setPreview(URL.createObjectURL(file)); // Create preview
      }
    } else {
      toast.error("Please upload files in JPG or PNG format only.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      toast.dismiss();
      toast.error("Please attach a payment screenshot before submitting.");
      return;
    }

    try {
      const response = await upload_payment_screenshot(
        user._id,
        selectedFile,
        token
      );

      toast.dismiss();
      toast.success(response.message);
    } catch (error) {
      console.error("Error uploading screenshot:", error);
    }

    // Reset form after submission
    setSelectedFile(null);
    setPreview(null);
    document.getElementById('formFile').value = '';
  };

  return (
    <div className="mt-4">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Please upload deposit confirmation receipt</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={handleFileChange}
          />
        </Form.Group>

        {preview && (
          <div className="mb-3">
            <p>Preview:</p>
            <Image
              src={preview}
              alt="Screenshot Preview"
              thumbnail
              style={{ height: "125px" }}
            />
          </div>
        )}

        <Button variant="primary" type="submit" className="submit-screen py-2 px-1 ms-0">
          Submit Screenshot
        </Button>
      </Form>
    </div>
  );
}

export default AttachedPaymentScreenshot;
