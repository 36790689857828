import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { useLocation, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner"; // Add spinner for loading state
import { getDirectUserReferal, getPoolAnount, get_single_user_busness, userSearchLevel } from "../../../common/Api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function MyTeam() {
  const query = new URLSearchParams(useLocation().search);
  const myParam = query.get('level');

  const { user, token } = useSelector((state) => state.user);
  const { ucode } = useParams();
  const [isSkelton, setIsSkelton] = useState(true);



  // States
  const [level1amount, setLevel1Amount] = useState(0);
  const [level2amount, setLevel2Amount] = useState(0);
  const [level3amount, setLevel3Amount] = useState(0);
  const [sponseremail, setSpomserEmail] = useState('');
  const [checkemail, setCheckEMail] = useState('');
  const [bussiness, setBussiness] = useState(0);
  const [users, setUsers] = useState([]);
  const [businessData, setBusinessData] = useState({}); // Store business data by user ID
  const [level, setLevel] = useState('1'); // Default level to 1
  const [filteredUsers, setFilteredUsers] = useState([]); // Filtered users based on level
  const [loading, setLoading] = useState(false); // State to track loading status
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const [perPage, setPerPage] = useState(10); // Rows per page state

  // Fetch direct referral data
  const getRankName = (rankId) => {
    const rankCritica = [
      { id: 1, name: "Basic Club" },
      { id: 2, name: "Meta Founder Club" },
      { id: 3, name: "Meta Star Club" },
      { id: 4, name: "Meta Royal Club" },
      { id: 5, name: "Meta Prime Club" },
      { id: 6, name: "Meta Ambassador Club" },
    ];
    const rank = rankCritica.find((r) => r.id === rankId);
    return rank ? rank.name : "-NA-"; // Return rank name or '-NA-' if not found
  };

  const handleShowBussinessAMount = async (id, email) => {
    try {
      const data = await get_single_user_busness(id);
      setBussiness(data);
      setCheckEMail(email);
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch business data.");
    }
  };

  const userPoolAmountData = async () => {
    try {
      const data = await getPoolAnount(user._id, token);
      setLevel1Amount(data.poolone);
      setLevel2Amount(data.pooltwo);
      setLevel3Amount(data.poolthree);
      setIsSkelton(false);
    } catch (error) {
      console.log(error);
    }
  };



  const columns = [
    {
      name: "#",
      selector: (row, index) => {
        // Calculate the serial number based on currentPage and perPage
        return index + 1 + (currentPage - 1) * perPage;
      },
      sortable: true,

    },
    {
      name: "Customer Name",
      selector: (row) => <>{row?.username} {row?.lastname}</>,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      className: "team_email"
    },
    {
      name: "Sponsor Email",
      selector: (row) => row?.referralEmail,
      sortable: true,
    },
    {
      name: "Level",
      selector: (row) => level,
      sortable: true,
    },
    {
      name: "Rank",
      selector: (row) => getRankName(row.btRank) || "-NA-",
      sortable: true,
    },
    {
      name: "Total Staking",
      selector: (row) => (
        <>
          {checkemail === row.email ? bussiness : (
            <button
              className="submit-screen"
              onClick={() => handleShowBussinessAMount(row._id, row.email)}
            >
              Show
            </button>
          )}
        </>
      ),
      sortable: true,
    },
    {
      name: "Joining Date",
      selector: (row) => new Date(row.createdAt).toLocaleDateString('en-GB'), // Format the date as DD/MM/YYYY
      sortable: true,
    },
  ];

  // Search by level
  const handleSerachLevel = async (selectedLevel) => {
    setLoading(true);
    try {
      setLevel(selectedLevel); // Update level state
      const levelsearch = await userSearchLevel(user._id, selectedLevel, token);
      setUsers(levelsearch);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Error during level search.");
    }
  };

  // Automatically search level 1 users when the component mounts
  useEffect(() => {
    handleSerachLevel('1');
    userPoolAmountData()
  }, []); // Empty dependency array means it will run only once after the component mounts

  return (
    <div className="container">
      <h5 className="mt-2 ml-3 text-white text-start fw-bold" style={{ margin: "10px" }}>
        My Team
      </h5>

      <div className="row m-2 text-start">
        <label htmlFor="level" style={{ color: '#ddaa26' }}>Select Level: </label>
        <div className="col-lg-3">
          <select
            id="level"
            className="form-control"
            value={level} // Bind the select element to the state
            onChange={(e) => handleSerachLevel(e.target.value)} // Trigger search on level change
          >
            {[...Array(10).keys()].map((num) => (
              <option key={num + 1} value={num + 1}>
                Level {num + 1}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4">
          <div className="level">
            Level 1: {isSkelton ? <Skeleton height={20} width={30} inline={true} /> : level1amount}
            BTMETA
          </div>
        </div>
        <div className="col-lg-4">
          <div className="level">
            Level 2: {isSkelton ? <Skeleton height={20} width={30} inline={true} /> : level2amount}
            BTMETA
          </div>
        </div>
        <div className="col-lg-4">
          <div className="level">
            Level 3: {isSkelton ? <Skeleton height={20} width={30} inline={true}/> : level3amount}
            BTMETA
          </div>
        </div>
      </div>



      {loading ? (
        <div className="text-center">
          <Spinner animation="border" />
          <p>Loading...</p>
        </div>
      ) : (
        <DataTable
          columns={columns}
          data={users}
          pagination
          highlightOnHover
          striped
          paginationPerPage={perPage}
          paginationDefaultPage={currentPage}
          onChangePage={page => setCurrentPage(page)} // Set current page
          onChangeRowsPerPage={newPerPage => setPerPage(newPerPage)} // Set rows per page
          className='dataTable'
        />
      )}
    </div>
  );
}
